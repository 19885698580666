<template>
  <SFaq />
</template>

<script>
import { SFaq } from '@slg/web-customer-shared';
import { getMetaInfo } from '@/utils/seo';

export default {
  name: 'FaqPage',
  components: {
    SFaq,
  },
  metaInfo() {
    return getMetaInfo(this.pageData?.Seo);
  },
}
</script>

<style lang="scss" scoped>
</style>
