var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"d-flex",class:{'flex-column': _vm.$vuetify.breakpoint.smAndDown, 'pl-6': _vm.$vuetify.breakpoint.smAndUp }},[_c('VCol',{staticClass:"d-flex align-items-center",class:{'flex-column': _vm.$vuetify.breakpoint.mdAndUp},attrs:{"lg":"3","md":"3","sm":"12","xs":"12"}},[_c('div',{class:{'w-100': _vm.$vuetify.breakpoint.mdAndUp}},[_c('VIcon',{staticClass:"checkout-icon",class:{'pr-1': _vm.$vuetify.breakpoint.smAndDown },attrs:{"large":""}},[_vm._v(" mdi-ticket ")])],1),_c('div',{class:{'w-100': _vm.$vuetify.breakpoint.mdAndUp}},[_c('span',{staticClass:"body-bold"},[_vm._v(_vm._s(_vm.$t('nightRider.title')))])])]),_c('VCol',{attrs:{"cols":"12","md":"8","lg":"8","sm":"11"}},[_c('div',{staticClass:"pb-3"},[_c('span',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(_vm.$t('nightRider.sectionTitle'))}})]),_c('ValidationObserver',{ref:"nightCards",attrs:{"name":"nightCards"}},[_c('div',_vm._l((_vm.cards),function(card,index){return _c('ValidationProvider',{key:index,ref:"card",refInFor:true,attrs:{"name":"nightCard","rules":{ min: 7, max: 10, onlyLettersAndNumbers: /^[a-zA-Z0-9]+$/, equalsCards: { cards: _vm.cards, cardValue: card.value } },"allowFalse":false,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('VTextField',{class:(((card.isBackendValid === false) || !card.isFrontValid) && !!card.value) ? 'pb-6' : '',attrs:{"clear-icon":"mdi-close-circle","clearable":"","label":_vm.$t('nightRider.nightCardLabel', { number: index + 1 }),"error":!!errors.length,"error-messages":(card.isBackendValid === false)
              ? _vm.$t('nightRider.nightCardIsNotValid', { number: card.value })
              : _vm.validationErrorResolve(errors[0])},on:{"input":function($event){[
              card.value = card.value
                ? card.value.toUpperCase()
                : card.value,
              _vm.removeFromLocalStorage(card),
              card.isBackendValid = null,
              card.isFrontValid = valid,
              _vm.checkCards(),
              _vm.trackNightCardEntering(card.value)
            ]}},model:{value:(card.value),callback:function ($$v) {_vm.$set(card, "value", $$v)},expression:"card.value"}})]}}],null,true)})}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }