<template>
  <SPassengerInfo
    :isAuth="getIsAuth"
    :isFreeTrip="getIsFreeTrip"
    :customerDetails="getUserDetailsForBookingDetails"
    :passengerPhone="getPassengerPhone"
    :passengerFirstName="getPassengerFirstName"
    :passengerLastName="getPassengerLastName"
    :passengerEmail="getPassengerEmail"
    :isAnotherPassenger="isAnotherPassenger"
    :isRideToOrFromAirport="isRideToOrFromAirport"
    :flightDetails="getFlightDetails"
    :paymentType="getPaymentType"
    :paymentMethod="getPaymentMethod"
    :isOnBoardPaymentAllowed="isOnBoardPaymentAllowed"
    :commentForOutward="getCommentForOutward"
    :commentForReturn="getCommentForReturn"
    :tripType="getTripType"
    :appConfig="appConfig"
    :lang="this.$route.params.lang"
    :anotherPassengersList="getAnotherPassengerList"
    :opsZoneCountry="getCountry"
    :userLocation="getLocation"
    @updatePhone="updatePhone"
    @updateFirstName="updateFirstName"
    @updateLastName="updateLastName"
    @updateEmail="updateEmail"
    @updatePassenger="updatePassenger"
    @updateFlightDetails="updateFlightDetails"
    @updatePaymentType="updatePaymentType"
    @updatePaymentMethod="updatePaymentMethod"
    @updateCommentForOutward="updateCommentForOutward"
    @updateCommentForReturn="updateCommentForReturn"
    @populateUserProfileInfo="populateUserProfileInfo"
    @setAuthToken="setAuthToken"
    @setGlobalLoading="setGlobalLoading"
    @trackGtmEventForRegisteredUser="onTrackEventForRegisteredUser"
    @trackGtmEventForNonRegisteredUser="onTrackEventForNonRegisteredUser"
    @trackGtmEventForUserCreatedByOperator="onTrackEventForUserCreatedByOperator"
  >
    <template #extendedCheckout>
      <NightCards @updateNightCards="validNightCards => $emit('updateNightCards', validNightCards)" />
    </template>
  </SPassengerInfo>
</template>

<script>
import { mapGetters } from 'vuex';
import appConfig from '@/appConfig';
import { SPassengerInfo } from '@slg/web-customer-shared';
import GTMixin from '@/mixins/GTMMixin';
import NightCards from './NightCards.vue';

export default {
  name: 'PassengerInfo',
  components: {
    SPassengerInfo,
    NightCards,
  },
  mixins: [GTMixin],
  data() {
    return {
      appConfig: {},
    }
  },
  computed: {
    ...mapGetters({
      getIsAuth: 'auth/getIsAuth',
      getUserDetailsForBookingDetails: 'auth/getUserDetailsForBookingDetails',
      getPassengerPhone: 'booking/getPassengerPhone',
      getPassengerFirstName: 'booking/getPassengerFirstName',
      getPassengerLastName: 'booking/getPassengerLastName',
      getPassengerEmail: 'booking/getPassengerEmail',
      isAnotherPassenger: 'booking/isAnotherPassenger',
      isRideToOrFromAirport: 'booking/isRideToOrFromAirport',
      getFlightDetails: 'booking/getFlightDetails',
      getPaymentType: 'booking/getPaymentType',
      getPaymentMethod: 'booking/getPaymentMethod',
      isOnBoardPaymentAllowed: 'opsZone/isOnBoardPaymentAllowed',
      getTripType: 'booking/getTripType',
      getCommentForOutward: 'booking/getCommentForOutward',
      getCommentForReturn: 'booking/getCommentForReturn',
      getIsFreeTrip: 'booking/getIsFreeTrip',
      getAnotherPassengerList: 'auth/getAnotherPassengerList',
      getCountry: 'opsZone/getCountry',
      getLocation: 'auth/getLocation',
    }),
  },
  created() {
    this.appConfig = appConfig;
  },
  methods: {
    updatePhone(newValue) {
      if (!newValue?.includes('undefined')) {
        this.$store.commit('booking/setPassengerPhone', newValue);
      }
    },
    updateFirstName(newValue) {
      this.$store.commit('booking/setPassengerFirstName', newValue);
    },
    updateLastName(newValue) {
      this.$store.commit('booking/setPassengerLastName', newValue);
    },
    updateEmail(newValue) {
      this.$store.commit('booking/setPassengerEmail', newValue);
    },
    updatePassenger(newValue) {
      if (newValue) {
        this.$store.commit('booking/setAnotherPassenger', newValue);
      } else {
        this.$store.commit('booking/setLoggedInPassengerDetails', this.getUserDetailsForBookingDetails);
      }
    },
    updateFlightDetails(newValue) {
      this.$store.commit('booking/setFlightDetails', newValue);
    },
    updatePaymentType(newValue) {
      this.$store.commit('booking/setPaymentType', newValue);
    },
    updatePaymentMethod(newValue) {
      this.$store.commit('booking/setPaymentMethod', newValue);
    },
    updateCommentForOutward(newValue) {
      this.$store.commit('booking/setCommentForOutward', newValue);
    },
    updateCommentForReturn(newValue) {
      this.$store.commit('booking/setCommentForReturn', newValue);
    },
    setGlobalLoading(isLoading) {
      this.$store.commit('common/setGlobalLoading', isLoading);
    },
    setAuthToken(authToken) {
      if (localStorage.getItem('authToken') !== authToken) {
        this.$store.commit('auth/clearAuthTokenAndProfileInfo');
        this.$store.commit('auth/setAuthToken', authToken);
      }
    },
    async populateUserProfileInfo() {
      try {
        await this.$store.dispatch('auth/populateUserProfileInfo');
        this.$emit('onSignInSuccess');
      } catch (e) {
        await this.setGlobalError(e);
      }
    },
    onTrackEventForRegisteredUser() {
      this.trackGtmEvent('Checkout auth next button for registered user click', 'Button', 'Click', 'Next step for registered user');
    },
    onTrackEventForNonRegisteredUser() {
      this.trackGtmEvent('Checkout auth next button for non registered user click', 'Button', 'Click', 'Next step for non-registered user');
    },
    onTrackEventForUserCreatedByOperator() {
      this.trackGtmEvent(
        'Checkout auth next button for registered user created by operator click',
        'Button',
        'Click',
        'Next step for created by operator user',
      );
    },
  },
}
</script>
