<template>
  <div
    class="d-flex flex-column w-100"
    :class="{ small: small }"
  >
    <VRow>
      <VCol>
        <h1 class="my-6 heading-3">
          {{ data.title }}
        </h1>
      </VCol>
    </VRow>
    <VRow v-if="data.sectionItem && !!data.sectionItem.length">
      <VCol
        v-for="item in data.sectionItem"
        :key="item.id"
        class="col mb-4 col-12"
        :lg="threeColumns ? 4 : 3"
        :md="threeColumns ? 4 : 3"
        :sm="threeColumns ? 4 : 6"
        col="12"
      >
        <div
          v-if="item.picture"
          class="image-col mb-6"
        >
          <img
            :src="item.picture.url"
            border="0"
          >
        </div>
        <VueMarkdown class="sub-title heading-6 mb-3 textPrimary--text">
          {{ item.subTitle }}
        </VueMarkdown>
        <VueMarkdown class="description body-2 textPrimary--text">
          {{ item.description }}
        </VueMarkdown>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import { OpenLinkNewTabMixin } from '@slg/web-customer-shared';

export default {
  name: 'AdditionalInfo',
  mixins: [OpenLinkNewTabMixin],
  components: {
    VueMarkdown,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    threeColumns: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setTargetAttributeToLink(['sub-title', 'description']);
  },
}

</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.image-col {
  height: 140px;
  text-align: center;
  img {
    max-width: 100%;
  }
}
.small {
  .heading-3 {
    font-size: $fs-20;
    line-height: $lh-27;
  }

  .heading-6 {
    font-size: $fs-16;
    line-height: $lh-24;
  }
}
</style>
