<template>
  <STripTypeSelection
    :tripType="getTripType"
    @setTripType="setTripType"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { STripTypeSelection } from '@slg/web-customer-shared';

export default {
  name: 'TripTypeSelection',
  components: {
    STripTypeSelection,
  },
  computed: {
    ...mapGetters({
      getTripType: 'booking/getTripType',
    }),
  },
  methods: {
    setTripType(newType) {
      this.$store.commit('booking/setTripType', newType);
      this.$emit('clearBookingRequestError');
    },
  },
}
</script>

<style scoped lang="scss">
</style>
