import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import BookingPage from '@/components/pages/BookingPage.vue';
import AboutPage from '@/components/pages/AboutPage.vue';
import FaqPage from '@/components/pages/FaqPage.vue';
import PrivacyPolicyPage from '@/components/pages/PrivacyPolicyPage.vue';
import TermsAndConditionsPage from '@/components/pages/TermsAndConditionsPage.vue';
import TripsPage from '@/components/pages/TripsPage.vue';
import TheMainContent from '@/components/TheMainContent.vue';
import SettingsPage from '@/components/pages/SettingsPage.vue';
import SettingsProfile from '@/components/settings/settingsProfile/SettingsProfile.vue'
import SettingsSecurity from '@/components/settings/settingsSecurity/SettingsSecurity.vue'
import { STrips, SCookie } from '@slg/web-customer-shared';
import CheckoutPage from '@/components/pages/CheckoutPage.vue';
import ConfirmationPage from '@/components/pages/ConfirmationPage.vue';
import AuthPage from '@/components/pages/AuthPage.vue';
import DeactivatedUserPage from '@/components/pages/DeactivatedUserPage.vue';
import NightCardPage from '@/components/pages/NightCardPage.vue';
import CommunePage from '@/components/nightCard/CommunePage.vue';
import ArticlePage from '@slg/web-customer-shared/src/components/articles/ArticlePage.vue';
import ArticlesPage from '@slg/web-customer-shared/src/components/articles/ArticlesPage.vue';
import ContactUsPage from '@/components/pages/ContactUsPage.vue';
import appConfig from '@/appConfig';
import store from '@/store';
import {
  marketingCookieConsent,
  cookieConsentValidValue,
  gtmCookiesPrefixes,
} from '@/utils/constants';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(Router);

function prefixRoutes(prefix, routes) {
  return routes.map((route) => ({
    ...route,
    path: `${prefix}/${route.path}`,
  }))
}

const router = new Router({
  mode: 'history',
  scrollBehavior(to) {
    const el = document.getElementById('main-container');
    const timeOutMs = to.name === 'Checkout' ? 1500 : 0;
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, timeOutMs)
  },
  routes: [
    {
      path: '/:lang',
      component: TheMainContent,
      redirect: (to) => ({ name: 'Booking', params: { lang: to.params.lang } }),
      children: [
        {
          path: 'booking',
          name: 'Booking',
          component: BookingPage,
        },
        {
          path: 'faq',
          name: 'FAQ',
          component: FaqPage,
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyPolicy',
          component: PrivacyPolicyPage,
        },
        {
          path: 'terms-and-conditions',
          name: 'TermsAndConditions',
          component: TermsAndConditionsPage,
        },
        {
          path: 'checkout',
          name: 'Checkout',
          component: CheckoutPage,
        },
        {
          path: 'contact-us',
          name: 'ContactUs',
          component: ContactUsPage,
        },
        {
          path: 'nightcard',
          name: 'NightCard',
          component: TheMainContent,
          redirect: { name: 'CommuneList' },
          children: [
            {
              path: '',
              name: 'CommuneList',
              component: NightCardPage,
            },
            {
              path: ':name',
              name: 'Commune',
              component: CommunePage,
            },
          ],
        },
        {
          path: 'articles',
          component: TheMainContent,
          children: [
            {
              path: '',
              name: 'ArticleList',
              component: ArticlesPage,
            },
            {
              path: ':name',
              name: 'Article',
              component: ArticlePage,
            },
          ],
        },
        {
          path: 'confirmation',
          name: 'Confirmation',
          component: ConfirmationPage,
        },
        {
          path: 'auth',
          name: 'Auth',
          component: AuthPage,
        },
        {
          path: 'about',
          name: 'About',
          component: AboutPage,
        },
        {
          path: 'deactivated-user',
          name: 'DeactivatedUser',
          component: DeactivatedUserPage,
        },
        ...prefixRoutes('user',
          [
            {
              path: 'settings',
              redirect: { name: 'Profile' },
              component: SettingsPage,
              meta: { auth: true },
              children: [
                {
                  path: 'profile',
                  name: 'Profile',
                  meta: { auth: true },
                  component: SettingsProfile,
                },
                {
                  path: 'security',
                  name: 'Security',
                  meta: { auth: true },
                  component: SettingsSecurity,
                },
              ],
            },
            {
              path: 'trips',
              name: 'Trips',
              redirect: { name: 'Planned' },
              component: TripsPage,
              meta: { auth: true },
              children: [
                {
                  path: 'planned',
                  name: 'Planned',
                  meta: { auth: true },
                  component: STrips,
                },
                {
                  path: 'past',
                  name: 'Past',
                  meta: { auth: true },
                  component: STrips,
                },
                {
                  path: 'canceled',
                  name: 'Canceled',
                  meta: { auth: true },
                  component: STrips,
                },
              ],
            },
          ]),
      ],
    },
    {
      path: '*',
      redirect: { name: 'Booking', params: { lang: 'en' } },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (!SCookie.cookieEnabledChecker(marketingCookieConsent, cookieConsentValidValue)) {
    SCookie.removeDomainCookiesByPrefix(gtmCookiesPrefixes);
  }

  const authToke = localStorage.getItem('authToken');

  if (authToke) {
    try {
      await store.dispatch('auth/populateUserProfileInfo');
      store.commit('auth/setAuthToken', authToke);
    } catch (e) {
      store.commit('auth/clearAuthTokenAndProfileInfo');
    }
  }

  const lang = from.params.lang || to.params.lang;
  const isUserLogged = store.getters['auth/getIsAuth'];

  if (to.meta.auth) {
    if (isUserLogged) {
      next();
    } else {
      next({ name: 'Booking', params: { lang: lang || appConfig.defaultLanguage } })
    }
    return;
  }

  if (store.getters['common/isGlobalLoading']) {
    store.commit('common/setGlobalLoading', false);
  }

  const temporaryReservationRemainingTime = store.getters['booking/getTemporaryReservationRemainingTime'];

  if (to.name !== 'Checkout' && to.name !== 'Confirmation') {
    if (isUserLogged && temporaryReservationRemainingTime > 0 && !to.params?.isErrorBookingConfirmation) {
      try {
        await store.dispatch('booking/cancelTemporaryReservation');
      } catch (e) {
        // todo
      }
    } else {
      store.commit('booking/clearTemporaryReservation');
    }
  }

  if (to.name === 'Checkout' && !store.getters['booking/getBookingRequestId']) {
    next({ name: 'Booking', params: { lang: lang || 'en' } });
    return;
  }

  if (to.name === 'Confirmation') {
    store.commit('booking/clearTemporaryReservation');
  }
  if (!appConfig.languages.includes(lang)) {
    next({ name: to.name, params: { lang: from.params.lang || appConfig.defaultLanguage } });
  } else {
    next();
  }
})

export default router;
