<template>
  <SUserLayout
    sidebarTitle="settings.title"
    sidebarIcon="mdi-cog"
    :sidebarItems="items"
  >
    <div class="setting-page-container__content d-flex justify-content-center">
      <RouterView />
    </div>
  </SUserLayout>
</template>

<script>
import SUserLayout from '@slg/web-customer-shared/src/components/layouts/userLayout/SUserLayout.vue';

export default {
  name: 'SettingsPage',
  components: {
    SUserLayout,
  },
  data() {
    return {
      items: [
        {
          name: this.$t('settings.profile'),
          route: 'profile',
        },
        {
          name: this.$t('settings.security'),
          route: 'security',
        },
      ],
    };
  },
}
</script>
