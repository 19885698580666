<template>
  <SFeaturesSelection
    :vehicleCategoryId="getVehicleCategoryId"
    :selectedFeatures="getSelectedFeatures"
    :animalsTransportation="getAnimalsTransportation"
    :rideType="getRideType"
    :currentLang="getCurrentLang"
    :appConfig="getAppConfig"
    :passengersAmount="getPassengersAmount"
    @setFeatureAmount="setFeatureAmount"
    @setAnimalsTransportation="setAnimalsTransportation"
  />
</template>
<script>
import appConfig from '@/appConfig';
import { mapGetters } from 'vuex';
import { SFeaturesSelection } from '@slg/web-customer-shared';

export default {
  name: 'FeaturesSelection',
  components: {
    SFeaturesSelection,
  },
  props: {
    isValidAmountOfPassenger: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getAdults: 'booking/getAdults',
      getChildren: 'booking/getChildren',
      getAnimalsTransportation: 'booking/getAnimalsTransportation',
      getRideType: 'booking/getRideType',
      getVehicleCategoryId: 'booking/getVehicleCategoryId',
      getSelectedFeatures: 'booking/getSelectedFeatures',
    }),
    getAppConfig() {
      return appConfig;
    },
    getCurrentLang() {
      return this.$route.params.lang;
    },
    getPassengersAmount() {
      return this.getAdults + this.getChildren;
    },
  },
  methods: {
    setFeatureAmount(feature) {
      this.$store.commit('booking/setFeatureAmount', feature);
      this.$emit('clearBookingRequestError');
    },
    setAnimalsTransportation(animalsTransportation) {
      this.$store.commit('booking/setAnimalsTransportation', animalsTransportation);
      this.$emit('clearBookingRequestError');
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
