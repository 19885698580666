import moment from 'moment-timezone';
import {
  STimeConverter, STripTypeEnum, SBookingRequestService, SReservationService, SBookingStopTypeEnum,
} from '@slg/web-customer-shared';
import { bookingDetails } from './bookingDetails/bookingDetailsState';

export default {
  namespaced: true,
  state: {
    ...bookingDetails,
  },
  mutations: {
    setArrivalPlace(state, data) {
      state.arrivalPlace.address = data.address;
      state.arrivalPlace.poiName = data.poiName;
      state.arrivalPlace.poiStopName = data.poiStopName;
      state.arrivalPlace.lat = data.lat;
      state.arrivalPlace.lon = data.lon;
      state.arrivalPlace.type = data.type;
      state.arrivalPlace.isPoi = data.isPoi;
      state.arrivalPlace.poiStopId = data.poiStopId;
      state.arrivalPlace.stopId = data.stopId;
      state.arrivalPlace.error = data.error;
    },
    clearArrivalPlace(state) {
      const keys = Object.keys(state.arrivalPlace);
      keys.forEach((key) => {
        state.arrivalPlace[key] = '';
      })
    },
    setDeparturePlace(state, data) {
      state.departurePlace.address = data.address;
      state.departurePlace.poiName = data.poiName;
      state.departurePlace.poiStopName = data.poiStopName;
      state.departurePlace.lat = data.lat;
      state.departurePlace.lon = data.lon;
      state.departurePlace.type = data.type;
      state.departurePlace.isPoi = data.isPoi;
      state.departurePlace.poiStopId = data.poiStopId;
      state.departurePlace.stopId = data.stopId;
      state.departurePlace.error = data.error;
    },
    clearDeparturePlace(state) {
      const keys = Object.keys(state.departurePlace);
      keys.forEach((key) => {
        state.departurePlace[key] = '';
      })
    },
    setRideType(state, rideType) {
      state.rideType = rideType;
    },
    populateBookingDetails(state, bookingDetails) {
      const keys = Object.keys(state.bookingDetails);
      keys.forEach((key) => {
        state.bookingDetails[key] = bookingDetails[key];
      });
    },
    clearBookingDetails(state) {
      state.bookingDetails = { ...bookingDetails };
    },
    setTripType(state, tripType) {
      state.tripType = tripType;
    },
    setAdults(state, number) {
      state.adults = number;
    },
    setChildren(state, number) {
      state.children = number;
    },
    setFeatures(state, selectedFeatures) {
      state.selectedFeatures = selectedFeatures;
    },
    setFeatureAmount(state, data) {
      const targetFeatureIndex = state.selectedFeatures.findIndex(({ vehicleFeatureId }) => vehicleFeatureId === data.vehicleFeatureId);
      state.selectedFeatures[targetFeatureIndex].amount = data.amount;
    },
    setAnimalsTransportation(state, animalsTransportation) {
      state.animalsTransportation = animalsTransportation;
    },
    setVehicleCategoryId(state, vehicleCategoryId) {
      state.vehicleCategoryId = vehicleCategoryId;
    },
    setVehicleCategoryLocalizedName(state, localizedName) {
      state.vehicleCategoryLocalizedName = localizedName;
    },
    setOutwardDate(state, data) {
      state.outwardDate.type = data.type;
      state.outwardDate.time = data.time;
      state.outwardDate.date = data.date;
    },
    setReturnDate(state, data) {
      state.returnDate.type = data.type;
      state.returnDate.time = data.time;
      state.returnDate.date = data.date;
    },
    populateRideList(state, rideList) {
      state.rideList = rideList;
    },
    setCommentForOutward(state, comment) {
      state.checkoutInfo.commentForOutward = comment;
    },
    setCommentForReturn(state, comment) {
      state.checkoutInfo.commentForReturn = comment;
    },
    setPaymentType(state, type) {
      state.checkoutInfo.paymentType = type;
    },
    setPaymentMethod(state, paymentMethod) {
      state.checkoutInfo.paymentMethod = paymentMethod;
    },
    setFlightDetails(state, details) {
      state.checkoutInfo.flightDetails = details;
    },
    setPassengerPhone(state, phone) {
      state.checkoutInfo.passengerDetails.phone = phone;
    },
    setPassengerFirstName(state, firstName) {
      state.checkoutInfo.passengerDetails.firstName = firstName;
    },
    setPassengerLastName(state, lastName) {
      state.checkoutInfo.passengerDetails.lastName = lastName;
    },
    setPassengerEmail(state, email) {
      state.checkoutInfo.passengerDetails.email = email;
    },
    setAnotherPassenger(state, isAnotherPassenger) {
      state.checkoutInfo.passengerDetails.isAnotherPassenger = isAnotherPassenger;
      state.checkoutInfo.passengerDetails.phone = '';
      state.checkoutInfo.passengerDetails.firstName = '';
      state.checkoutInfo.passengerDetails.lastName = '';
      state.checkoutInfo.passengerDetails.email = '';
    },
    populateTemporaryReservation({ checkoutInfo }, reservationDetails) {
      checkoutInfo.temporaryReservation.bookingRequestId = reservationDetails.bookingRequestId;
      checkoutInfo.temporaryReservation.reservationId = reservationDetails.reservationId;
      checkoutInfo.temporaryReservation.remainingTime = Math.round((reservationDetails.reservedTill - reservationDetails.now) / 1000);
      checkoutInfo.temporaryReservation.reservedTill = reservationDetails.reservedTill;
    },
    setRemainingTime({ checkoutInfo }, remainingTime) {
      checkoutInfo.temporaryReservation.remainingTime = remainingTime;
    },
    setIgnoreRemainingTime({ checkoutInfo }, ignoreRemainingTime) {
      checkoutInfo.temporaryReservation.ignoreRemainingTime = ignoreRemainingTime;
    },
    setLoggedInPassengerDetails({ checkoutInfo }, passengerDetails) {
      checkoutInfo.passengerDetails.isAnotherPassenger = false;
      checkoutInfo.passengerDetails.phone = passengerDetails.phone;
      checkoutInfo.passengerDetails.firstName = passengerDetails.firstName;
      checkoutInfo.passengerDetails.lastName = passengerDetails.lastName;
      checkoutInfo.passengerDetails.email = passengerDetails.email;
    },
    setUpdatedPriceByNightCards({ bookingDetails }, price) {
      bookingDetails.originalOutwardFeaturePrice = Number(price.originalOutwardFeaturePrice);
      bookingDetails.originalOutwardPrice = Number(price.originalOutwardPrice);
      bookingDetails.originalReturnFeaturePrice = Number(price.originalReturnFeaturePrice);
      bookingDetails.originalReturnPrice = Number(price.originalReturnPrice);
    },
    clearDates({ outwardDate, returnDate }) {
      outwardDate.type = '';
      outwardDate.time = '';
      outwardDate.date = '';
      returnDate.type = '';
      returnDate.time = '';
      returnDate.date = '';
    },
    clearOptions(state) {
      state.animalsTransportation = false;
      state.rideType = '';
      state.tripType = 'ONE_WAY';
      state.vehicleCategoryId = '';
      state.adults = 1;
      state.children = 0;
      state.selectedFeatures = [];
    },
    clearTemporaryReservationDeadline(state) {
      state.checkoutInfo.temporaryReservation.remainingTime = 0;
    },
    clearTemporaryReservation(state) {
      state.checkoutInfo.temporaryReservation.bookingRequestId = '';
      state.checkoutInfo.temporaryReservation.reservedTill = 0;
      state.checkoutInfo.temporaryReservation.reservationId = '';
      state.checkoutInfo.temporaryReservation.remainingTime = 300;
      state.bookingDetails.bookingRequestId = '';
    },
    clearBookingState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(bookingDetails)));
    },
  },
  actions: {
    async setBookingDetails({ commit }, bookingCreateModel) {
      const response = await SBookingRequestService.bookingCreate(bookingCreateModel);
      commit('populateBookingDetails', response.data);
    },
    async setTemporaryReservation({ commit }, bookingRequestId) {
      const now = new Date().valueOf();
      const response = await SReservationService.reservationCreate(bookingRequestId);
      response.data.now = now;
      commit('populateTemporaryReservation', response.data);
    },
    async cancelTemporaryReservation({ commit, state }) {
      if (state.checkoutInfo.temporaryReservation.reservationId) {
        try {
          await SReservationService.reservationCancel(state.checkoutInfo.temporaryReservation.bookingRequestId)
        } catch (_error) {
          // do nothing
        } finally {
          commit('clearTemporaryReservation');
        }
      }
    },
  },
  getters: {
    getBookingForm: (state) => state,
    getBookingDetails: (state) => state.bookingDetails,
    getAnimalsTransportation: (state) => state.animalsTransportation,
    getReturnDate: (state) => state.returnDate.date,
    getReturnState: (state) => state.returnDate,
    getReturnTime: (state) => state.returnDate.time,
    getReturnType: (state) => state.returnDate.type,
    getOutwardDate: (state) => state.outwardDate.date,
    getOutwardState: (state) => state.outwardDate,
    getOutwardTime: (state) => state.outwardDate.time,
    getOutwardType: (state) => state.outwardDate.type,
    getAdults: (state) => state.adults,
    getChildren: (state) => state.children,
    atLeastOnePassengerSelected: (state) => (state.adults + state.children) > 0,
    getVehicleCategoryId: (state) => state.vehicleCategoryId,
    getVehicleCategoryLocalizedName: (state) => state.vehicleCategoryLocalizedName,
    getSelectedFeatures: (state) => state.selectedFeatures,
    getRideType: (state) => state.rideType,
    getTripType: (state) => state.tripType,
    isRoundTrip: (state) => state.tripType === STripTypeEnum.ROUND_TRIP,
    getArrivalPlace: (state) => state.arrivalPlace,
    isArrivalPlaceFilled: ({ arrivalPlace }) => arrivalPlace.address && arrivalPlace.lat && arrivalPlace.lon,
    getDeparturePlace: (state) => state.departurePlace,
    isDeparturePlaceFilled: ({ departurePlace }) => departurePlace.address && departurePlace.lat && departurePlace.lon,
    isRideToOrFromAirport: ({ departurePlace, arrivalPlace }) => ((departurePlace.isPoi && departurePlace.type === 'airport')
      || (arrivalPlace.isPoi && arrivalPlace.type === 'airport')),
    getStops: (state) => [state.departurePlace, state.arrivalPlace],
    getSelectedRides: (state, getters) => ([
      {
        title: getters.isRoundTrip ? 'bookingForm.outwardTime' : 'bookingForm.oneWayOutwardTime',
        priceTitle: getters.isRoundTrip ? 'bookingForm.outwardRidePrice' : 'bookingForm.oneWayOutwardRidePrice',
        featurePriceTitle: getters.isRoundTrip ? 'bookingForm.outwardRideFeatures' : 'bookingForm.oneWayOutwardRideFeatures',
        outward: state.departurePlace,
        arrival: state.arrivalPlace,
        rideType: state.rideType,
        timeZone: moment.tz.guess(),
        isDepartureExactTime: state.outwardDate.type === SBookingStopTypeEnum.DEPARTURE_TIME,
        dateState: { time: STimeConverter.convertStringToMilliseconds(state.outwardDate.date, state.outwardDate.time) },
        bookingStopType: state.outwardDate.type,
        tripDuration: state.bookingDetails.outwardTimeInMinutes,
        timeInMinutes: state.bookingDetails.outwardTimeInMinutes * 60 * 1000,
        distanceKm: state.bookingDetails.outwardDistanceKm,
        maxDetourTime: state.bookingDetails.outwardMaxDetourTimeInMinutes,
        featurePrice: state.bookingDetails.originalOutwardFeaturePrice,
        ridePrice: state.bookingDetails.originalOutwardPrice,
      },
      ...(getters.isRoundTrip
        ? [{
          title: 'bookingForm.returnTime',
          priceTitle: 'bookingForm.returnRidePrice',
          featurePriceTitle: 'bookingForm.returnRideFeatures',
          outward: state.arrivalPlace,
          arrival: state.departurePlace,
          rideType: state.rideType,
          timeZone: moment.tz.guess(),
          isDepartureExactTime: state.returnDate.type === SBookingStopTypeEnum.DEPARTURE_TIME,
          dateState: { time: STimeConverter.convertStringToMilliseconds(state.returnDate.date, state.returnDate.time) },
          bookingStopType: state.returnDate.type,
          tripDuration: state.bookingDetails.returnTimeInMinutes,
          timeInMinutes: state.bookingDetails.returnTimeInMinutes * 60 * 1000,
          distanceKm: state.bookingDetails.returnDistanceKm,
          maxDetourTime: state.bookingDetails.returnMaxDetourTimeInMinutes,
          featurePrice: state.bookingDetails.originalReturnFeaturePrice,
          ridePrice: state.bookingDetails.originalReturnPrice,
        }]
        : []
      ),
    ]),
    isOutwardVehicleFound: (state) => state.isOutwardVehicleFound,
    isReturnVehicleFound: (state) => state.isReturnVehicleFound,
    getPaymentType: (state) => state.checkoutInfo.paymentType,
    getPaymentMethod: (state) => state.checkoutInfo.paymentMethod,
    getCheckoutInfo: (state) => state.checkoutInfo,
    getFlightDetails: (state) => state.checkoutInfo.flightDetails,
    getCommentForOutward: (state) => state.checkoutInfo.commentForOutward,
    getCommentForReturn: (state) => state.checkoutInfo.commentForReturn,
    getPassengerPhone: (state) => state.checkoutInfo.passengerDetails.phone,
    getPassengerFirstName: (state) => state.checkoutInfo.passengerDetails.firstName,
    getPassengerLastName: (state) => state.checkoutInfo.passengerDetails.lastName,
    getPassengerEmail: (state) => state.checkoutInfo.passengerDetails.email,
    isAnotherPassenger: (state) => state.checkoutInfo.passengerDetails.isAnotherPassenger,
    getTemporaryReservationDeadline: (state) => state.checkoutInfo.temporaryReservation.reservedTill,
    getTemporaryReservationRemainingTime: (state) => state.checkoutInfo.temporaryReservation.remainingTime,
    getIgnoreRemainingTime: (state) => state.checkoutInfo.temporaryReservation.ignoreRemainingTime,
    getReservationId: (state) => state.checkoutInfo.temporaryReservation.reservationId,
    getBookingRequestId: (state) => state.bookingDetails.bookingRequestId,
    getIsFreeTrip: (state) => !Number(state.bookingDetails.originalOutwardPrice)
      || (!Number(state.bookingDetails.originalOutwardPrice) && !Number(state.bookingDetails.originalReturnPrice)),
    getRejectReasonCode: (state) => state.bookingDetails.rejectReasonCode,
  },
}
