export const opsZoneDetails = {
  currentOpsZone: {
    city: null,
    country: null,
    holidays: [],
    maxNumberOfDaysForFutureBooking: null,
    minTimeBeforeBooking: null,
    onBoardPaymentAllowed: null,
    opsRegionId: null,
    opsZoneId: null,
    opsZoneArea: null,
    opsZoneName: null,
    opsZoneRestrictionAreas: null,
    pointOfInterestIds: null,
    serviceShortName: null,
    shuttleType: null,
    stopWaitingTime: null,
    vehicleCategories: null,
    zoneWeekDays: null,
    timeZone: null,
    // mocked
    allowOnDemandPickUpTime: null,
    isFreeTrip: false,
  },
}
