<template>
  <SUserLayout
    sidebarTitle="trips.title"
    sidebarIcon="mdi-ticket-confirmation"
    :sidebarItems="sidebarItems"
  >
    <div class="d-flex justify-content-center">
      <RouterView
        :currentTabName="currentTabName"
        :appConfig="appConfig"
        @update-trips-count="updateTripsCount"
      />
    </div>
  </SUserLayout>
</template>

<script>
import SUserLayout from '@slg/web-customer-shared/src/components/layouts/userLayout/SUserLayout.vue';
import appConfig from '@/appConfig';

export default {
  name: 'TripsPage',
  components: {
    SUserLayout,
  },
  data() {
    return {
      tripsCount: {
        cancelled: null,
        past: null,
        planned: null,
      },
      appConfig: {},
    };
  },
  computed: {
    currentTabName() {
      return this.$route.name;
    },
    sidebarItems() {
      return [
        {
          name: `${this.$t('trips.planned')} ${this.tripsCount.planned !== null ? `(${this.tripsCount.planned})` : ''}`,
          route: 'planned',
        },
        {
          name: `${this.$t('trips.past')} ${this.tripsCount.past !== null ? `(${this.tripsCount.past})` : ''}`,
          route: 'past',
        },
        {
          name: `${this.$t('trips.canceled')} ${this.tripsCount.cancelled !== null ? `(${this.tripsCount.cancelled})` : ''}`,
          route: 'canceled',
        },
      ];
    },
  },
  created() {
    this.appConfig = appConfig;
  },
  methods: {
    updateTripsCount(tripsCount) {
      this.tripsCount = tripsCount;
    },
  },
}
</script>
