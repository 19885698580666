<template>
  <SRideTypeSelection
    :appConfig="appConfig"
    :rideType="getRideType"
    :animalsTransportation="getAnimalsTransportation"
    :availableShuttleType="getAvailableShuttleType"
    @setRideType="setRideType"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { SRideTypeSelection } from '@slg/web-customer-shared';
import appConfig from '@/appConfig';

export default {
  name: 'RideTypeSelection',
  components: {
    SRideTypeSelection,
  },
  data() {
    return {
      appConfig: {},
    }
  },
  computed: {
    ...mapGetters({
      getAnimalsTransportation: 'booking/getAnimalsTransportation',
      getRideType: 'booking/getRideType',
      getAvailableShuttleType: 'opsZone/getAvailableShuttleType',
    }),
  },
  created() {
    this.appConfig = appConfig;
  },
  methods: {
    setRideType(newType) {
      this.$store.commit('booking/setRideType', newType);
      this.$emit('clearBookingRequestError');
    },
  },
}
</script>

<style scoped lang="scss">
</style>
