<template>
  <div class="commune-group-item">
    <div class="letter">
      <span class="heading-6">
        {{ communeGroup.letter.toUpperCase() }}
      </span>
    </div>
    <div class="communes-group-container pt-2 d-flex flex-column">
      <RouterLink
        :is="item.isOutOfService ? 'span' : 'router-link'"
        v-for="(item, key) in communeGroup.items"
        :key="key"
        class="pt-2 body-2"
        :class="item.isOutOfService ? 'textSecondary--text' : 'secondary--text'"
        :to="{
          name: 'Commune',
          params: {
            name: item.slug,
          },
        } "
      >
        {{ item.title }} {{ item.isOutOfService ? '(not-provide)' : '' }}
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommuneGroupItem',
  props: {
    communeGroup: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped lang="scss">
.disabled {

}
</style>
