<template>
  <SPassengersSelection
    :adultsAmount="getAdults"
    :childrenAmount="getChildren"
    :vehicleCategoryId="getVehicleCategoryId"
    :vehicleCategories="getVehicleCategories"
    :appConfig="appConfig"
    :wheelchairPlacesAmount="getWheelchairPlacesAmount"
    @updateAdults="updateAdults"
    @updateChildren="updateChildren"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { SPassengersSelection, SConstants } from '@slg/web-customer-shared';
import appConfig from '@/appConfig';

export default {
  name: 'PassengersSelection',
  components: {
    SPassengersSelection,
  },
  data() {
    return {
      appConfig: {},
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      getAdults: 'booking/getAdults',
      getChildren: 'booking/getChildren',
      getVehicleCategoryId: 'booking/getVehicleCategoryId',
      getVehicleCategories: 'opsZone/getVehicleCategories',
      getSelectedFeatures: 'booking/getSelectedFeatures',
    }),
    getWheelchairPlacesAmount() {
      const wheelChairFeature = this.getSelectedFeatures?.find((feature) => feature.vehicleFeatureId === SConstants.WHEELCHAIR_PLACE_ID);
      return wheelChairFeature?.amount;
    },
  },
  created() {
    this.appConfig = appConfig;
  },
  methods: {
    updateAdults(newValue) {
      this.$store.commit('booking/setAdults', newValue);
      this.$emit('clearBookingRequestError');
    },
    updateChildren(newValue) {
      this.$emit('clearBookingRequestError');
      this.$store.commit('booking/setChildren', newValue);
    },
  },
}
</script>

<style scoped>

</style>
