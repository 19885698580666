import { extend } from 'vee-validate';

extend('equalsCards', {
  params: ['cards', 'cardValue'],
  validate: (value, args) => {
    const sameCards = args.cards.filter(({ value }) => value === args.cardValue);
    return (sameCards.length === 1);
  },
  message: 'nightRider.validations.sameCards',
})
