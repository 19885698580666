<template>
  <SDatesSelection
    :outwardState="getOutwardState"
    :returnState="getReturnState"
    :tripType="getTripType"
    :minTimeBeforeBooking="getMinTimeBeforeBooking"
    :maxNumberOfDaysForFutureBooking="getMaxNumberOfDaysForFutureBooking"
    :allowOnDemandPickUpTime="allowOnDemandPickUpTime"
    :workingHoursZoneWeekDays="getWorkingHoursZoneWeekDays"
    :holidays="getHolidays"
    :timeZone="getTimeZone"
    @clearDates="clearDates"
    @setOutwardDate="setOutwardDate"
    @setReturnDate="setReturnDate"
    @trackGtmEventApplyDateAndTimeForOutward="onTrackEventApplyDateAndTimeForOutward"
    @trackGtmEventApplyDateAndTimeForReturn="onTrackEventApplyDateAndTimeForReturn"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { SDatesSelection } from '@slg/web-customer-shared';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'DatesSelection',
  components: {
    SDatesSelection,
  },
  mixins: [GTMixin],
  computed: {
    ...mapGetters({
      getReturnTime: 'booking/getReturnTime',
      getOutwardState: 'booking/getOutwardState',
      getReturnState: 'booking/getReturnState',
      getHolidays: 'opsZone/getHolidays',
      getTripType: 'booking/getTripType',
      getMinTimeBeforeBooking: 'opsZone/getMinTimeBeforeBooking',
      getMaxNumberOfDaysForFutureBooking: 'opsZone/getMaxNumberOfDaysForFutureBooking',
      allowOnDemandPickUpTime: 'opsZone/allowOnDemandPickUpTime',
      getWorkingHoursZoneWeekDays: 'opsZone/getWorkingHoursZoneWeekDays',
      getTimeZone: 'opsZone/getTimeZone',
    }),
  },
  methods: {
    clearDates() {
      this.$store.commit('booking/clearDates');
    },
    setOutwardDate(outwardDate) {
      this.$store.commit('booking/setOutwardDate', outwardDate);
      this.$emit('clearBookingRequestError');
    },
    setReturnDate(returnDate) {
      this.$store.commit('booking/setReturnDate', returnDate);
      this.$emit('clearBookingRequestError');
    },
    openDialog(name) {
      this.currentComponent = name;
      this.isDialogOpen = true;
    },
    changeStep(name) {
      this.currentComponent = name;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    onTrackEventApplyDateAndTimeForOutward() {
      this.trackGtmEvent('Apply date and time for outward trip button click', 'Button', 'Click', 'Date and Time for outward trip selected');
    },
    onTrackEventApplyDateAndTimeForReturn() {
      this.trackGtmEvent('Apply date and time for return trip button click', 'Button', 'Click', 'Date and Time for return trip selected');
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
