<template>
  <SFooter
    :menuItems="getStrappiCoreMenu(menuItems)"
    :isShownPrivacyPreferences="getIsShownPrivacyPreferences"
    @openCookieDialog="openCookieDialog"
  >
    <template #customTopImages>
      <div class="position-absolute img-container flex-wrap">
        <div
          class="img-wrapper left overflow-hidden"
          :class="{
            'white': getIsCheckoutPage && !getIsMobile,
            'grey': !getIsCheckoutPage && !getIsMobile,
          }"
        >
          <img
            :src="require(`@/assets/img/footer-img-left${getIsMobile ? '-xs' : ''}.svg`)"
            :class="{ 'mobile': getIsMobile }"
            alt=""
          >
        </div>
        <div
          v-if="!getIsMobile"
          class="middle-block"
        />
        <div
          v-if="!getIsMobile"
          class="img-wrapper right"
          :class="{'mobile': getIsMobile, 'white': getIsCheckoutPage && !getIsMobile, 'grey': !getIsCheckoutPage && !getIsMobile}"
        >
          <img
            :src="require('@/assets/img/footer-img-right.svg')"
            alt=""
          >
        </div>
      </div>
    </template>
    <template #customSocial>
      <a
        v-for="(item, key) in getStrappiCoreSettings.social"
        :key="key"
        :href="item.link"
        :class="getStrappiCoreSettings.social.length - 1 !== key ? 'pr-6' : ''"
        target="_blank"
      >
        <img
          :class="getStrappiCoreSettings.social.length - 1 ? 'mr-6' : ''"
          :src="item.image && item.image.url"
          alt="logo"
        >
      </a>
    </template>
    <template #imgLeft>
      <img
        v-if="getStrappiCoreSettings.footerImgLeft"
        :src="getStrappiCoreSettings.footerImgLeft.url"
      >
    </template>
    <template #imgRight>
      <img
        v-if="getStrappiCoreSettings.footerImgRight"
        :src="getStrappiCoreSettings.footerImgRight.url"
      >
    </template>
    <template #rights>
      {{ getStrappiCoreSettings.footerRights }}
    </template>
    <template #bottomLeft>
      <VueMarkdown
        v-if="getStrappiCoreSettings.footerBottomLeft"
      >
        {{ getStrappiCoreSettings.footerBottomLeft }}
      </VueMarkdown>
    </template>
    <template #bottomRight>
      <VueMarkdown
        v-if="getStrappiCoreSettings.footerBottomRight"
      >
        {{ getStrappiCoreSettings.footerBottomRight }}
      </VueMarkdown>
    </template>
  </SFooter>
</template>

<script>
import { SFooter } from '@slg/web-customer-shared';
import appConfig from '@/appConfig';
import { mapGetters } from 'vuex';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'Footer',
  components: {
    SFooter,
    VueMarkdown,
  },
  props: {
    isFooterFullyShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getStrappiCoreMenu: 'common/getStrappiCoreMenu',
      getStrappiCoreSettings: 'common/getStrappiCoreSettings',
    }),
    getIsShownPrivacyPreferences() {
      return appConfig.extendedCookieSupport;
    },
    getIsMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    getIsCheckoutPage() {
      return this.$route.name === 'Checkout';
    },
    menuItems() {
      return [
        {
          strapiText: '',
          strapiKey: 'homeTitle',
          isAnotherDomain: false,
          title: 'footer.home',
          path: '/',
        },
        {
          title: 'footer.about',
          isAnotherDomain: false,
          path: { name: 'About' },
        },
        {
          title: 'footer.lost&found',
          isAnotherDomain: false,
          path: { name: 'ContactUs' },
        },
        {
          strapiText: '',
          isAnotherDomain: false,
          strapiKey: 'FAQTitle',
          title: 'footer.faq',
          path: { name: 'FAQ' },
        },
        {
          strapiText: '',
          isAnotherDomain: false,
          strapiKey: 'contactUsTitle',
          title: 'footer.contactUs',
          path: { name: 'ContactUs' },
        },
        {
          title: 'footer.jobs',
          isAnotherDomain: true,
          path: { name: this.getJobPage },
        },
        {
          strapiText: '',
          strapiKey: 'termsTitle',
          isAnotherDomain: false,
          title: 'footer.t&c',
          path: { name: 'TermsAndConditions' },
        },
        {
          strapiText: '',
          isAnotherDomain: true,
          strapiKey: 'policyTitle',
          title: 'footer.privacyPolicy',
          path: { name: this.getPrivacyPolicyPage },
        },
      ];
    },
    getJobPage() {
      if (this.$route.params.lang === 'de') {
        return 'https://www.slg.lu/de/career/';
      }

      if (this.$route.params.lang === 'fr') {
        return 'https://www.slg.lu/career/';
      }

      return 'https://www.slg.lu/en/career/';
    },
    getPrivacyPolicyPage() {
      if (this.$route.params.lang === 'de') {
        return 'https://www.slg.lu/de/politique-de-confidentialite/';
      }

      if (this.$route.params.lang === 'fr') {
        return 'https://www.slg.lu/politique-de-confidentialite/';
      }

      return 'https://www.slg.lu/en/politique-de-confidentialite/';
    },
  },
  methods: {
    openCookieDialog() {
      this.$store.commit('common/changeCookieDialogOpenState', true);
    },
  },
}
</script>
<style lang="scss" scoped>
.img-container {
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;

  .img-wrapper {
    position: absolute;
    max-width: 100%;
    z-index: 1;

    &.mobile {
      background-color: unset;
    }

    &.grey {
      background-color: var(--v-pageBg-base) !important;
    }

    &.white {
      background-color: var(--v-cardBg-base);
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    img {
      max-width: 100%;

       &.mobile {
        max-width: unset;
      }
    }
  }

  .middle-block {
    position: absolute;
    max-width: 100%;
    right: 0;
    width: 100%;
    height: 27px;
    z-index: 0;
    background-color: #424242;
  }
}
</style>
