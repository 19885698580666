<template>
  <div class="checkout-container overflow-x-hidden">
    <Checkout />
  </div>
</template>
<script>
import Checkout from '@/components/checkout/Checkout.vue';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'CheckoutPage',
  components: {
    Checkout,
  },
  mixins: [GTMixin],
  mounted() {
    this.trackGtmEvent('Checkout page', 'Page', 'Loaded', 'Checkout page is loaded');
  },
}
</script>
<style lang="scss" scoped>
.checkout-container {
  background-color: var(--v-cardBg-base);
  padding-top: 76px;
  min-height: 100%;

  @media screen and (max-width: 1264px) {
    min-height: 100vh;
  }
}
</style>
