<template>
  <SPrivacyPolicy />
</template>

<script>
import { SPrivacyPolicy } from '@slg/web-customer-shared';
import { getMetaInfo } from '@/utils/seo';

export default {
  name: 'PrivacyPolicyPage',
  components: {
    SPrivacyPolicy,
  },
  metaInfo() {
    return getMetaInfo(this.pageData?.Seo);
  },
}
</script>

<style lang="scss">
.privacy-policy-page-container {
  margin: 0 auto;
  min-height: 400px;
}
</style>
