<template>
  <VDialog
    :value="isCookieDialogShown"
    scrollable
    max-width="480px"
    @click:outside="$emit('close')"
  >
    <VCard tile>
      <VCardTitle class="justify-space-between px-4">
        <h6 class="heading-6">
          {{ popUpTitle }}
        </h6>
        <VBtn
          class="grey-lighten-4 box-shadow-elevation-6"
          icon
          @click.stop="$emit('close')"
        >
          <VIcon color="textPrimary">
            mdi-close
          </VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText class="pt-4 px-4">
        <div class="pb-6">
          <VueMarkdown class="body-1 description">
            {{ popUpDescription }}
          </VueMarkdown>
        </div>
        <div>
          <div class="d-flex justify-content-between pb-6">
            <div class="d-flex flex-column">
              <span class="heading-6">{{ essentialCookiesTexts.title }}</span>
              <VueMarkdown class="body-1 description">
                {{ essentialCookiesTexts.description }}
              </VueMarkdown>
            </div>
            <VSwitch
              class="mt-0 d-flex align-items-center py-0 switch"
              color="brandingPrimary"
              :input-value="isEssentialCookiesAccepted"
              disabled
              inset
              hide-details
            />
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span class="heading-6">{{ marketingCookiesTexts.title }}</span>
              <VueMarkdown class="body-1 description">
                {{ marketingCookiesTexts.description }}
              </VueMarkdown>
            </div>
            <VSwitch
              class="mt-0 d-flex align-items-center py-0 switch"
              color="brandingPrimary"
              :input-value="isMarketingCookiesAccepted"
              inset
              hide-details
              @change="$emit('toggleMarketingCookies')"
            />
          </div>
        </div>
      </VCardText>
      <VCardActions
        class="flex-column pb-4"
        :class="{ 'flex-column': $vuetify.breakpoint.xs }"
      >
        <div
          class="d-flex w-100 pb-3"
          :class="{ 'flex-column': $vuetify.breakpoint.xs }"
        >
          <div
            class="px-1 w-50"
            :class="{'w-100 mb-3': $vuetify.breakpoint.xs}"
          >
            <VBtn
              v-blur
              class="w-100 button"
              outlined
              @click.stop="$emit('close')"
            >
              {{ $t('cookieManagement.close') }}
            </VBtn>
          </div>
          <div
            class="px-1 w-50"
            :class="{'w-100': $vuetify.breakpoint.xs}"
          >
            <VBtn
              v-blur
              class="w-100 button"
              color="error"
              outlined
              @click.stop="$emit('toggleAllUnnecessaryCookies')"
            >
              <div class="error--text">
                {{ $t('cookieManagement.denyAll') }}
              </div>
            </VBtn>
          </div>
        </div>
        <div
          class="px-1 w-100"
        >
          <VBtn
            v-blur
            class="w-100 text-decoration-none button"
            color="brandingPrimary"
            dark
            @click.stop="onSave"
          >
            {{ $t('cookieManagement.saveMyPreferences') }}
          </VBtn>
        </div>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { OpenLinkNewTabMixin } from '@slg/web-customer-shared';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'CookieDialog',
  components: {
    VueMarkdown,
  },
  mixins: [OpenLinkNewTabMixin],
  props: {
    isCookieDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
    popUpTitle: {
      type: String,
      default: '',
    },
    popUpDescription: {
      type: String,
      default: '',
    },
    essentialCookiesTexts: {
      type: Object,
      default: null,
    },
    marketingCookiesTexts: {
      type: Object,
      default: null,
    },
    isEssentialCookiesAccepted: {
      type: Boolean,
      default: false,
    },
    isMarketingCookiesAccepted: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isCookieDialogShown: {
      handler() {
        this.setTargetAttributeToLink(['description']);
      },
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.$emit('acceptCookieConsent');
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.switch {
  margin-right: -12px !important;
}

.description {
  margin-bottom: -16px;
}
</style>
