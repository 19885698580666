import { opsZoneDetails } from './opsZoneDetails/opsZoneDetails';

export default {
  namespaced: true,
  state: {
    ...opsZoneDetails,
  },
  mutations: {
    populateOpsZone(state, opsZoneResponse) {
      const keys = Object.keys(state.currentOpsZone);
      keys.forEach((key) => {
        state.currentOpsZone[key] = opsZoneResponse[key];
      })
      state.currentOpsZone.zoneWeekDays = opsZoneResponse.zoneWeekDays.splice(-1).concat(state.currentOpsZone.zoneWeekDays);
    },
    clearOpsZone(state) {
      Object.assign(state, JSON.parse(JSON.stringify(opsZoneDetails)));
    },
  },
  getters: {
    getCurrentOpsZone: (state) => state.currentOpsZone,
    getVehicleCategories: (state) => state.currentOpsZone.vehicleCategories,
    getRideTypes: (state) => state.currentOpsZone.shuttleType,
    getAvailableShuttleType: (state) => state.currentOpsZone.shuttleType,
    getMaxNumberOfDaysForFutureBooking: (state) => state.currentOpsZone.maxNumberOfDaysForFutureBooking,
    getMinTimeBeforeBooking: (state) => state.currentOpsZone.minTimeBeforeBooking,
    getWorkingHoursZoneWeekDays: (state) => state.currentOpsZone.zoneWeekDays,
    getHolidays: (state) => state.currentOpsZone.holidays,
    isOpsZoneSelected: (state) => state.currentOpsZone.opsZoneId,
    getTimeZone: (state) => state.currentOpsZone.timeZone,
    isOnBoardPaymentAllowed: (state) => state.currentOpsZone.onBoardPaymentAllowed,
    allowOnDemandPickUpTime: (state) => state.currentOpsZone.allowOnDemandPickUpTime,
    isFreeTrip: (state) => state.currentOpsZone.isFreeTrip,
    getCountry: (state) => state.currentOpsZone.country,
  },
}
