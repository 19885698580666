<template>
  <div class="d-flex flex-column">
    <div class="booking-container position-relative">
      <BookingBg class="booking-bg" />
      <div class="container--fluid h-100 position-relative d-flex justify-content-center flex-wrap mx-6">
        <VRow
          class="booking-container__top d-flex justify-content-center position-relative breakpoint-xl align-items-top flex-wrap"
          style="z-index: 1"
        >
          <VCol
            cols="12"
            lg="5"
            md="5"
            sm="12"
            xs="12"
            class="col-12 mt-24 offset-md-1 offset-lg-2"
          >
            <div
              v-if="homePageData"
              class="title-info"
            >
              <div class="heading-5 pre-title textWhite--text mb-2">
                {{ homePageData.preTitle }}
              </div>
              <h1 class="heading-3 mb-3 textWhite--text">
                {{ homePageData.title }}
              </h1>
              <div class="subtitle-1 textWhite--text">
                {{ homePageData.subTitle }}
              </div>
            </div>
          </VCol>
          <VCol
            cols="12"
            lg="4"
            md="6"
            sm="8"
            xs="12"
            class="d-flex justify-center col-12 offset-lg-1"
          >
            <BookingForm class="booking-form-container" />
          </VCol>
        </VRow>
      </div>
    </div>
    <div
      v-if="homePageData"
      class="h-100 position-relative d-flex justify-content-center flex-wrap mx-6 flex-column align-center"
    >
      <TryOurApp
        v-if="homePageData.tryOurApp"
        :data="homePageData.tryOurApp"
        class="wrap mb-6 w-100 try-app"
      />
      <AdditionalInfo
        v-if="homePageData.section2"
        :data="homePageData.section2"
        class="wrap mb-6 w-100"
      />
      <AdditionalInfo
        v-if="homePageData.section3"
        :data="homePageData.section3"
        :threeColumns="true"
        class="wrap mb-6 w-100"
      />
      <TwoColumnInfo
        v-if="homePageData.section1"
        :data="homePageData.section1"
        class="wrap mb-6 w-100"
      />
    </div>
  </div>
</template>

<script>

import { getMetaInfo } from '@/utils/seo';
import { SStrapiService, SUpdateCmsDataMixin } from '@slg/web-customer-shared';
import TwoColumnInfo from '@/components/strapi/TwoColumnInfo.vue';
import AdditionalInfo from '@/components/strapi/AdditionalInfo.vue';
import TryOurApp from '@/components/strapi/TryOurApp.vue';
import BookingForm from '@/components/bookingForm/BookingForm.vue';
import BookingBg from '@/components/bookingForm/BookingBg.vue';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'BookingPage',
  components: {
    BookingForm,
    BookingBg,
    TwoColumnInfo,
    AdditionalInfo,
    TryOurApp,
  },
  mixins: [SUpdateCmsDataMixin, GTMixin],
  data() {
    return {
      homePageData: null,
    }
  },
  metaInfo() {
    return getMetaInfo(this.homePageData?.Seo);
  },
  mounted() {
    this.trackGtmEvent('Booking page', 'Page', 'Loaded', 'Booking page is loaded');
  },
  methods: {
    async loadCmsData() {
      try {
        await this.getHomePageData();
      } catch (e) {
        // to-do rethink about catch
      }
    },
    async getHomePageData() {
      this.homePageData = await SStrapiService.getHomePage();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.booking-container {
  padding-top: 24px;
  padding-bottom: 31px;
  margin-bottom: 61px;
  min-height: 537px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;

  .booking-container__top {
    .title-info {
      background: rgba(0, 0, 0, 0.45);
      border: 2px solid;
      border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(96, 96, 96, 0.2) 100%);
      border-radius: 8px;
      padding: 24px;
    }

    .subtitle-1 {
      max-width: 320px;
    }
  }

  .booking-form-container {
    z-index: 1;
  }

  .breakpoint-xl {
    @media screen and (max-width: 960px) {
      max-width: 810px;
    }
  }
}

.wrap {
  max-width: 810px;
}

@media screen and (min-width: 1280px) {
  .offset-lg-2 {
    margin-right: 16.6666666667% !important;
    margin-left: unset !important;
  }

   .offset-lg-1 {
    margin-right: 8.3333333333% !important;
    margin-left: unset !important;
  }

  .booking-container__top {
    .title-info {
      margin-top: 130px;
    }
  }
}

@media screen and (min-width: 960px) {
  .booking-form-container {
    margin-bottom: auto;
  }
  .booking-container__top {
    .title-info {
      margin-top: 95px;
    }
  }
}

@media screen and (max-width: 960px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-32;
      line-height: $lh-43;
      letter-spacing: $ls-0025;
    }
  }
}

@media screen and (max-width: 600px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-20;
    }

    .subtitle-1 {
      font-size: $fs-14;
    }
  }
}
</style>
