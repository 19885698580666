<template>
  <SDeactivatedUser />
</template>

<script>
import { SDeactivatedUser } from '@slg/web-customer-shared';

export default {
  name: 'DeactivatedUserPage',
  components: {
    SDeactivatedUser,
  },
  beforeRouteEnter(to, from, next) {
    if (to.params?.isAuthFailed) {
      next();
    } else {
      // redirect to home page
      next('');
    }
  },
}
</script>
