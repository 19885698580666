<template>
  <div class="position-absolute w-100 h-100 booking-img-container d-flex justify-content-center">
    <div class="position-relative w-100 h-100 img-container justify-content-center">
      <div class="position-absolute bottom-left-img-container" />
      <div
        class="position-absolute bottom-right-img-container"
        :style="{ backgroundImage: `url(${require('@/assets/img/triangles.svg')})` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingBg',
}
</script>

<style scoped lang="scss">

.booking-img-container {
  background-color: var(--v-textWhite-base) ;
  top: 0;
}

.img-container {
  top: 0;
  bottom: 0;
  z-index: 0;

  .bottom-left-img-container {
    bottom: 0;
    left: 0;
    background-image: url("../../assets/img/background-lux.png");
    background-repeat: no-repeat;
    background-position-x: center;
    width: 100%;
    height: 100%;
  }

  .bottom-right-img-container {
    width: 100%;
    height: 30px;
    bottom: -30px;
    background-repeat: repeat;
  }
}
</style>
