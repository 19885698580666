export const bookingDetails = {
  arrivalPlace: {
    address: '',
    poiName: '',
    poiStopName: '',
    lat: '',
    lon: '',
    type: '',
    isPoi: false,
    poiStopId: '',
    stopId: '',
    error: '',
  },
  departurePlace: {
    address: '',
    poiName: '',
    poiStopName: '',
    lat: '',
    lon: '',
    type: '',
    isPoi: false,
    poiStopId: '',
    stopId: '',
    error: '',
  },
  animalsTransportation: false,
  rideType: '',
  tripType: 'ONE_WAY',
  outwardDate: {
    type: '',
    date: '',
    time: '',
  },
  returnDate: {
    type: '',
    date: '',
    time: '',
  },
  vehicleCategoryId: '',
  vehicleCategoryLocalizedName: '',
  adults: 1,
  children: 0,
  selectedFeatures: [],
  rideList: [],
  checkoutInfo: {
    passengerDetails: {
      isAnotherPassenger: false,
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    paymentType: 'PRE_AUTHORIZATION',
    paymentMethod: null,
    flightDetails: '',
    commentForOutward: '',
    commentForReturn: '',
    temporaryReservation: {
      bookingRequestId: '',
      reservationId: '',
      reservedTill: null,
      remainingTime: null,
      ignoreRemainingTime: false,
    },
  },
  bookingDetails: {
    bookingRequestId: null,
    originalOutwardFeaturePrice: null,
    originalOutwardPrice: null,
    outwardMaxDetourTimeInMinutes: null,
    originalReturnFeaturePrice: null,
    originalReturnPrice: null,
    outwardDistanceKm: null,
    outwardTimeInMinutes: null,
    outwardTripAllowedReason: null,
    outwardTripAllowedShiftId: null,
    outwardTripAllowedTripId: null,
    outwardTripAllowedVehicleId: null,
    rejectReasonCode: null,
    rejectReasonMessage: null,
    rejected: true,
    returnMaxDetourTimeInMinutes: null,
    returnDistanceKm: null,
    returnTimeInMinutes: null,
    returnTripAllowedReason: null,
    returnTripAllowedShiftId: null,
    returnTripAllowedTripId: null,
    returnTripAllowedVehicleId: null,
    selectedFeatures: null,
  },
}
