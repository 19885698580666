<template>
  <AuthForm @onSignInSuccess="toMainPage" />
</template>

<script>
import AuthForm from '@/components/authForm/AuthForm.vue';

export default {
  name: 'AuthPage',
  components: {
    AuthForm,
  },
  methods: {
    toMainPage() {
      this.$router.push({ name: 'Booking' });
    },
  },
}
</script>

<style scoped>

</style>
