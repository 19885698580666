<template>
  <SComfortSelection
    :vehicleCategoryId="getVehicleCategoryId"
    :comfortTypeItems="comfortTypeItems"
    @setComfortType="setComfortType"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { SComfortSelection } from '@slg/web-customer-shared';

export default {
  name: 'ComfortSelection',
  components: {
    SComfortSelection,
  },
  computed: {
    ...mapGetters({
      getVehicleCategoryId: 'booking/getVehicleCategoryId',
      getVehicleCategories: 'opsZone/getVehicleCategories',
    }),
    comfortTypeItems() {
      const availableVehicleTypes = this.getVehicleCategories.map((category) => (
        {
          text: category.localizedName[this.$route.params.lang]
            || category.localizedName.en
            || category.identifier,
          value: category.vehicleCategoryId,
        }
      ));
      return availableVehicleTypes || [];
    },
  },
  methods: {
    setComfortType(newValue) {
      const {
        vehicleCategoryId,
        vehicleFeatures,
        maxSpecialSeatCapacity,
        localizedName,
      } = this.getVehicleCategories.find((category) => category.vehicleCategoryId === newValue);
      this.$store.commit('booking/setVehicleCategoryId', vehicleCategoryId);
      this.$store.commit('booking/setVehicleCategoryLocalizedName', localizedName);
      this.$emit('clearBookingRequestError');
      this.setVehicleFeatures(vehicleFeatures, maxSpecialSeatCapacity);
    },
    setVehicleFeatures(vehicleFeatures, maxSpecialSeatCapacity) {
      const selectedFeatures = vehicleFeatures.map((feature) => (
        {
          ...feature,
          amount: 0,
          showIcon: false,
        }
      ));
      if (maxSpecialSeatCapacity) {
        const localizedVehicleFeatureName = {
          en: 'Wheelchair Place',
          fr: 'Fauteuil roulant',
          es: 'Wheelchair Place -es',
          pt: 'Wheelchair Place -pt',
          nl: 'Wheelchair Place -nl',
          de: 'Rollstuhl',
          it: 'Wheelchair Place -it',
        }
        const wheelchairPlace = {
          vehicleFeatureId: 'wheelchairPlace',
          capacity: maxSpecialSeatCapacity,
          amount: 0,
          price: 0,
          localizedVehicleFeatureName,
          svgIconBase64: 'mdi-wheelchair-accessibility',
          showIcon: true,
        };
        selectedFeatures.push(wheelchairPlace);
      }
      this.$store.commit('booking/setFeatures', selectedFeatures);
    },
  },
}
</script>

<style scoped lang="scss">
</style>
