<template>
  <VExpandTransition>
    <VCard
      v-show="!isCookieEnabled && !isCookieDialogShown"
      class="cookie-banner"
      color="brandingPrimary"
      data-test="cookieBanner"
      tile
    >
      <VCardText class="breakpoint-xl d-flex">
        <div class="d-flex w-100">
          <VRow class="d-flex align-items-center justify-content-between w-100">
            <VCol
              cols="12"
              lg="6"
              md="6"
              sm="12"
            >
              <VueMarkdown
                v-if="bannerDescription"
                class="body-1 description textWhite--text"
              >
                {{ bannerDescription }}
              </VueMarkdown>
            </VCol>
            <VCol
              cols="12"
              lg="6"
              md="6"
              sm="12"
            >
              <div
                class="d-flex"
                :class="{
                  'justify-content-end': $vuetify.breakpoint.smAndUp,
                  'justify-content-center': $vuetify.breakpoint.sm,
                  'flex-column-reverse': $vuetify.breakpoint.xs
                }"
              >
                <VBtn
                  v-blur
                  class="br-8"
                  :class="{
                    'mr-4': !$vuetify.breakpoint.xs,
                    'mt-3': $vuetify.breakpoint.xs
                  }"
                  color="white"
                  outlined
                  @click="$emit('openCookieDialog')"
                >
                  <span class="button">
                    {{ manageBtnText }}
                  </span>
                </VBtn>
                <div
                  class="d-flex"
                  :class="{
                    'justify-content-end': !$vuetify.breakpoint.xs,
                    'justify-content-between w-100': $vuetify.breakpoint.xs
                  }"
                >
                  <VBtn
                    v-blur
                    class="br-8"
                    :class="{
                      'mr-4': !$vuetify.breakpoint.xs,
                      'deny-button': $vuetify.breakpoint.xs
                    }"
                    color="white"
                    outlined
                    @click="deny"
                  >
                    <span class="button">
                      {{ $t('cookieManagement.denyAll') }}
                    </span>
                  </VBtn>
                  <VBtn
                    v-blur
                    class="br-8"
                    :class="{
                      'mr-4': !$vuetify.breakpoint.xs,
                      'accept-button': $vuetify.breakpoint.xs
                    }"
                    color="white"
                    data-test="cookieActionButton"
                    @click="$emit('acceptCookieConsent', true)"
                  >
                    <span class="button">
                      {{ acceptBtnText }}
                    </span>
                  </VBtn>
                </div>
              </div>
            </VCol>
          </VRow>
        </div>
      </VCardText>
    </VCard>
  </VExpandTransition>
</template>

<script>
import { OpenLinkNewTabMixin } from '@slg/web-customer-shared';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'CookieBanner',
  components: {
    VueMarkdown,
  },
  mixins: [OpenLinkNewTabMixin],
  props: {
    isCookieDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
    isCookieEnabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    bannerDescription: {
      type: String,
      default: '',
    },
    manageBtnText: {
      type: String,
      default: '',
    },
    acceptBtnText: {
      type: String,
      default: '',
    },
  },
  watch: {
    bannerDescription: {
      handler() {
        this.setTargetAttributeToLink(['description']);
      },
      immediate: true,
    },
  },
  methods: {
    deny() {
      this.$emit('toggleAllUnnecessaryCookies');
      this.$emit('acceptCookieConsent', false);
    },
  },
}
</script>

<style scoped lang="scss">
.cookie-banner {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
  bottom: 0;

  .description {
    margin-bottom: -16px !important;
  }

  .accept-button,
  .deny-button {
    width: 49%;
  }
}
</style>
