import { SCheckoutErrorsEnum, SConstants } from '@slg/web-customer-shared';

export const resolveReservationError = (error, clearTemporaryReservationDeadline) => {
  let isNeededVehicleFound = null;
  let isOutwardVehicleFound = null;
  let isReturnVehicleFound = null;
  let isAnotherDateSelectionDialogOpen = false;
  let isServerErrorDialogOpen = false;
  let isWriteConflict = null;
  if (error?.errorCode === SCheckoutErrorsEnum.errorDetails.D2D_BOOKING_OUTWARD_AND_RETURN_VEHICLE_NOT_FOUND
    || (error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_FAILED
    && error.errorDetails[0] === SCheckoutErrorsEnum.errorDetails.D2D_BOOKING_OUTWARD_AND_RETURN_VEHICLE_NOT_FOUND)) {
    isNeededVehicleFound = false;
    isOutwardVehicleFound = false;
    isReturnVehicleFound = false;
    isAnotherDateSelectionDialogOpen = true;
  } else if (error?.errorCode === SCheckoutErrorsEnum.errorDetails.D2D_BOOKING_OUTWARD_VEHICLE_NOT_FOUND
    || (error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_FAILED
    && error.errorDetails[0] === SCheckoutErrorsEnum.errorDetails.D2D_BOOKING_OUTWARD_VEHICLE_NOT_FOUND)) {
    isNeededVehicleFound = false;
    isOutwardVehicleFound = false;
    isReturnVehicleFound = true;
    isAnotherDateSelectionDialogOpen = true;
  } else if (error?.errorCode === SCheckoutErrorsEnum.errorDetails.D2D_BOOKING_RETURN_VEHICLE_NOT_FOUND) {
    isNeededVehicleFound = false;
    isOutwardVehicleFound = true;
    isReturnVehicleFound = false;
    isAnotherDateSelectionDialogOpen = true;
  } else if (error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_CANNOT_BE_PROLONGED
        || error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_EXPIRED_EXCEPTION) {
    clearTemporaryReservationDeadline();
  } else if (error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_NOT_FOUND_EXCEPTION
        || error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_CANNOT_BE_PROCESSED) {
    isServerErrorDialogOpen = true;
  } else if (error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_FAILED_PERIOD_OCCUPIED
        || error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_REQUEST_IS_REJECTED
        || error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_REQUEST_THERE_IS_NO_ACTIVE_VEHICLES
        || error?.errorDetails[0] === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_REQUEST_THERE_IS_NO_ACTIVE_VEHICLES
        || error?.errorCode === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_REQUEST_THERE_IS_NO_AVAILABLE_SUB_PERIODS_FOR_TRIP_ASSIGN
    // eslint-disable-next-line max-len
        || error?.errorDetails[0] === SCheckoutErrorsEnum.D2D_BOOKING_RESERVATION_REQUEST_THERE_IS_NO_AVAILABLE_SUB_PERIODS_FOR_TRIP_ASSIGN) {
    isNeededVehicleFound = false;
    isOutwardVehicleFound = false;
    isReturnVehicleFound = false;
    isAnotherDateSelectionDialogOpen = true;
  } else if (error?.message.match(SConstants.THIS_OPERATION_CONFLICTED_WITH_ANOTHER_OPERATION_REGEXP)) {
    isWriteConflict = true;
  } else {
    isServerErrorDialogOpen = true;
  }
  return {
    isNeededVehicleFound,
    isOutwardVehicleFound,
    isReturnVehicleFound,
    isAnotherDateSelectionDialogOpen,
    isServerErrorDialogOpen,
    isWriteConflict,
  }
}
