<template>
  <SSettingsSecurity />
</template>

<script>
import { SSettingsSecurity } from '@slg/web-customer-shared';

export default {
  name: 'SettingsSecurity',
  components: {
    SSettingsSecurity,
  },
}

</script>
