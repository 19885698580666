<template>
  <VCard
    class="w-100 booking-form-container box-shadow-elevation-8"
    :loading="loading"
  >
    <VCardTitle>
      <h5 class="heading-5 textPrimary--text">
        {{ $t('bookingForm.searchYouRide') }}
      </h5>
    </VCardTitle>
    <ValidationObserver
      ref="bookingForm"
      name="bookingForm"
      tag="form"
    >
      <VForm @submit.prevent="onSubmit">
        <VCardText class="pb-0">
          <SSearchResultDialog
            :visible="showSearchResultDialog"
            :selectedRides="getSelectedRides"
            :showSearchResultDialogLoading="showSearchResultDialogLoading"
            @close="onCloseSearchResultDialog"
            @toCheckoutPage="toCheckoutPage"
          />
          <DirectionSelection
            ref="directionSelection"
            @clearBookingRequestError="bookingRequestErrorCode = null"
          />
          <template v-if="isOpsZoneSelected">
            <RideTypeSelection
              class="mt-1"
              @clearBookingRequestError="bookingRequestErrorCode = null"
            />
            <TripTypeSelection
              class="mt-1"
              @clearBookingRequestError="bookingRequestErrorCode = null"
            />
            <DatesSelection
              ref="datesSelection"
              class="mt-3"
              @clearBookingRequestError="bookingRequestErrorCode = null"
            />
            <ComfortSelection
              v-show="getVehicleCategories.length > 1"
              @clearBookingRequestError="bookingRequestErrorCode = null"
            />
            <VExpansionPanels
              v-model="openedPanels"
              multiple
              eager
            >
              <PassengersSelection
                ref="passengersSelection"
                @clearBookingRequestError="bookingRequestErrorCode = null"
              />
              <FeaturesSelection
                v-show="isFeatureSelectionAvailable"
                ref="featuresSelection"
                @clearBookingRequestError="bookingRequestErrorCode = null"
              />
            </VExpansionPanels>
            <SBookingRequestError
              v-if="bookingRequestErrorCode"
              :bookingRequestErrorCode="bookingRequestErrorCode"
            />
          </template>
        </VCardText>
        <VCardActions class="px-4 mt-6 pt-0 pb-4">
          <VBtn
            id="submit-button"
            class="w-100 box-shadow-elevation-4 border-radius-8 booking-btn"
            dark
            color="brandingPrimary"
            type="submit"
          >
            <span class="button">
              {{ $t('bookingForm.getQuote') }}
            </span>
            <span class="button-icon mr-2">
              <VIcon
                size="large"
              >mdi-chevron-right</VIcon>
            </span>
          </VBtn>
        </VCardActions>
      </VForm>
    </ValidationObserver>
  </VCard>
</template>

<script>
import { mapGetters } from 'vuex';
import appConfig from '@/appConfig';
import {
  SSearchResultDialog, SBookingRequestError, SGlobalErrorDialogMixin, STimeConverter, SConstants,
  SRideTypeEnum, STripTypeEnum, SDateTypeEnum,
} from '@slg/web-customer-shared';
import GTMixin from '@/mixins/GTMMixin';
import DirectionSelection from './directionSelection/DirectionSelection.vue';
import RideTypeSelection from './rideTypeSelection/RideTypeSelection.vue';
import TripTypeSelection from './tripTypeSelection/TripTypeSelection.vue';
import ComfortSelection from './comfortSelection/ComfortSelection.vue';
import DatesSelection from './datesSelection/DatesSelection.vue';
import PassengersSelection from './passengersSelection/PassengersSelection.vue';
import FeaturesSelection from './featuresSelection/FeaturesSelection.vue';

export default {
  name: 'BookingForm',
  components: {
    SSearchResultDialog,
    SBookingRequestError,
    DirectionSelection,
    RideTypeSelection,
    TripTypeSelection,
    ComfortSelection,
    DatesSelection,
    PassengersSelection,
    FeaturesSelection,
  },
  mixins: [
    SGlobalErrorDialogMixin,
    GTMixin,
  ],
  data() {
    return {
      loading: false,
      showSearchResultDialog: false,
      showSearchResultDialogLoading: false,
      bookingRequestErrorCode: null,
      openedPanels: [],
    }
  },
  computed: {
    ...mapGetters({
      getAdults: 'booking/getAdults',
      getChildren: 'booking/getChildren',
      getOutwardDate: 'booking/getOutwardDate',
      getOutwardTime: 'booking/getOutwardTime',
      getOutwardType: 'booking/getOutwardType',
      getRideType: 'booking/getRideType',
      getTripType: 'booking/getTripType',
      getReturnDate: 'booking/getReturnDate',
      getReturnTime: 'booking/getReturnTime',
      getReturnType: 'booking/getReturnType',
      getAnimalsTransportation: 'booking/getAnimalsTransportation',
      getStops: 'booking/getStops',
      getSelectedRides: 'booking/getSelectedRides',
      getBookingRequestId: 'booking/getBookingRequestId',
      getVehicleCategories: 'opsZone/getVehicleCategories',
      getTimeZone: 'opsZone/getTimeZone',
      getVehicleCategoryId: 'booking/getVehicleCategoryId',
      atLeastOnePassengerSelected: 'booking/atLeastOnePassengerSelected',
      getSelectedFeatures: 'booking/getSelectedFeatures',
      isOpsZoneSelected: 'opsZone/isOpsZoneSelected',
      getRejectReasonCode: 'booking/getRejectReasonCode',
    }),
    isFeatureSelectionAvailable() {
      return this.getSelectedFeatures?.length > 0 || !appConfig.isNightCardsSupport;
    },
    amountPassengersError() {
      return ((this.atLeastOnePassengerSelected || !this.atLeastOnePassengerSelected)
        && this.$refs && this.$refs.passengers) ? this.$refs.passengers.errors.counter[0] : '';
    },
    maxStandardSeats() {
      const selectedVehicleCategory = this.getVehicleCategories
        ?.find((vehicleCategory) => vehicleCategory.vehicleCategoryId === this.getVehicleCategoryId);
      return selectedVehicleCategory?.maxStandardSeatCapacity + this.getWheelchairPlacesAmountOrdered;
    },
    getWheelchairPlacesAmountOrdered() {
      const wheelChairFeature = this.getSelectedFeatures?.find((feature) => feature.vehicleFeatureId === SConstants.WHEELCHAIR_PLACE_ID);
      return wheelChairFeature?.amount;
    },
    isMaxSeatsAreReached() {
      return this.getAdults + this.getChildren === this.maxStandardSeats;
    },
    componentWithError() {
      const firstFailedObserver = this.$refs.bookingForm.observers.find((observer) => observer.flags.invalid);
      return this.$refs[firstFailedObserver.$attrs.name]
    },
  },
  methods: {
    onCloseSearchResultDialog() {
      this.showSearchResultDialog = false;
      this.trackGtmEvent('Close requested trip pop-up button click', 'Button', 'Click', 'Close requested trip pop-up');
    },
    toCheckoutPage() {
      this.trackGtmEvent('Confirm requested trip button click', 'Button', 'Click', 'Confirm requested trip');
      this.$router.push({ name: 'Checkout' });
    },
    buildSaveModel() {
      const model = {};
      model.adultsCount = this.getAdults;
      model.animalsTransportation = this.getAnimalsTransportation;
      model.arrivalTimeDeviation = null; //
      model.infantsCount = this.getChildren;
      model.opsZoneId = this.isOpsZoneSelected;
      model.outwardDateTime = STimeConverter.convertDateAndTimeToTimeZone(this.getOutwardDate, this.getOutwardTime, this.getTimeZone);
      model.outwardDeparture = this.getOutwardType === SDateTypeEnum.DEPARTURE_TIME;
      model.partnerId = null;
      model.privateShuttle = this.getRideType === SRideTypeEnum.PRIVATE;
      model.returnDateTime = this.getTripType === STripTypeEnum.ROUND_TRIP
        ? STimeConverter.convertDateAndTimeToTimeZone(this.getReturnDate, this.getReturnTime, this.getTimeZone)
        : null;
      model.returnDeparture = this.getReturnType === SDateTypeEnum.DEPARTURE_TIME;
      model.roundTrip = this.getTripType === STripTypeEnum.ROUND_TRIP;
      model.selectedFeatures = this.getSelectedFeatures.filter((feature) => feature.vehicleFeatureId !== SConstants.WHEELCHAIR_PLACE_ID);
      model.selectedFeatures = model.selectedFeatures.reduce((results, feature) => {
        if (feature.amount) {
          results.push({
            capacity: feature.amount,
            vehicleFeatureId: feature.vehicleFeatureId,
          });
        }
        return results;
      }, []);
      model.specialSeatsCount = this.getWheelchairPlacesAmountOrdered;
      model.stops = this.getStops.map((stop) => ({
        address: stop.address,
        latitude: stop.lat,
        longitude: stop.lon,
        poiStopId: stop.poiStopId,
      }));
      model.vehicleCategoryId = this.getVehicleCategoryId;
      return model;
    },
    expandSections() {
      const passengerSelectionError = this.$refs.bookingForm.observers
        .find((observer) => observer.flags.invalid && observer.$attrs.name === 'passengersSelection');
      const featuresSelectionError = this.$refs.bookingForm.observers
        .find((observer) => observer.flags.invalid && observer.$attrs.name === 'featuresSelection');

      if (passengerSelectionError && featuresSelectionError) {
        this.openedPanels = [0, 1];
      } else if (passengerSelectionError && !featuresSelectionError) {
        this.openedPanels = [0];
      } else if (!passengerSelectionError && featuresSelectionError) {
        this.openedPanels = [1];
      }
    },
    onConfirmBookingRequest() {
      this.trackGtmEvent('Confirm requested trip button click', 'Button', 'Click', 'Confirm requested trip');
    },
    async onSubmit() {
      this.trackGtmEvent('Get a quote button click', 'Button', 'Click', 'Get a quote');
      this.$refs.bookingForm.validate().then(async (success) => {
        if (!success) {
          this.expandSections();
          setTimeout(() => {
            this.componentWithError.$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          }, 100);
          return;
        }
        this.$nextTick(async () => {
          const model = this.buildSaveModel();
          this.showSearchResultDialogLoading = true;
          this.trackGtmEvent('Booking form details set', 'Pop Up', 'Open', 'Confirmation pop-up is shown');
          try {
            this.showSearchResultDialog = true;
            await this.$store.dispatch('booking/setBookingDetails', model);
            if (this.getRejectReasonCode) {
              this.bookingRequestErrorCode = this.getRejectReasonCode;
              this.showSearchResultDialog = false;
            }
          } catch (e) {
            this.showSearchResultDialog = false;
            await this.setGlobalError(e);
          }
          this.showSearchResultDialogLoading = false;
        });
      });
    },
  },
}
</script>
