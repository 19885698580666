<template>
  <div>
    <CookieDialog
      :isCookieDialogShown="isCookieDialogShown"
      :popUpTitle="popUpTitle"
      :popUpDescription="popUpDescription"
      :essentialCookiesTexts="essentialCookiesTexts"
      :marketingCookiesTexts="marketingCookiesTexts"
      :isEssentialCookiesAccepted="isEssentialCookiesAccepted"
      :isMarketingCookiesAccepted="isMarketingCookiesAccepted"
      @toggleMarketingCookies="isMarketingCookiesAccepted = !isMarketingCookiesAccepted"
      @toggleAllUnnecessaryCookies="toggleAllUnnecessaryCookies"
      @acceptCookieConsent="acceptCookieConsent"
      @close="close"
    />
    <CookieBanner
      :bannerDescription="bannerDescription"
      :manageBtnText="manageBtnText"
      :acceptBtnText="acceptBtnText"
      :isCookieEnabled="isCookieEnabled"
      :isCookieDialogShown="isCookieDialogShown"
      @openCookieDialog="isCookieDialogShown = true"
      @toggleAllUnnecessaryCookies="toggleAllUnnecessaryCookies"
      @acceptCookieConsent="(acceptAll) => acceptCookieConsent(acceptAll)"
    />
  </div>
</template>

<script>
import { SCookie, SStrapiService, SUpdateCmsDataMixin } from '@slg/web-customer-shared';
import CookieBanner from '@/components/cookie/CookieBanner.vue';
import CookieDialog from '@/components/cookie/CookieDialog.vue';
import { mapGetters } from 'vuex';
import {
  essentialCookieConsent,
  marketingCookieConsent,
  cookieConsentValidValue,
  cookieConsentNotValidValue,
  cookieMaxDaysExpire,
  gtmCookiesPrefixes,
  oldCookieConsent,
} from '@/utils/constants';

export default {
  name: 'CookieManagement',
  components: {
    CookieBanner,
    CookieDialog,
  },
  mixins: [SUpdateCmsDataMixin],
  data() {
    return {
      isCookieEnabled: true,
      isEssentialCookiesAccepted: true,
      isMarketingCookiesAccepted: false,
      isCookieDialogShown: false,
      essentialCookiesTexts: {
        title: '',
        description: '',
      },
      marketingCookiesTexts: {
        title: '',
        description: '',
      },
      manageBtnText: '',
      acceptBtnText: '',
      bannerDescription: '',
      popUpTitle: '',
      popUpDescription: '',
    };
  },
  computed: {
    ...mapGetters({
      getIsShownCookieDialog: 'common/getIsShownCookieDialog',
    }),
  },
  watch: {
    getIsShownCookieDialog: {
      handler() {
        this.isCookieDialogShown = this.getIsShownCookieDialog;
      },
      immediate: true,
    },
    isCookieDialogShown: {
      handler() {
        this.isMarketingCookiesAccepted = SCookie.cookieEnabledChecker(marketingCookieConsent, cookieConsentValidValue);
      },
      immediate: true,
    },
  },
  created() {
    if (SCookie.cookieEnabledChecker(oldCookieConsent, cookieConsentValidValue)) {
      SCookie.deleteCookie(oldCookieConsent);
    }
  },
  methods: {
    async loadCmsData() {
      try {
        const {
          manageButton,
          acceptButton,
          bannerDescription,
          essentialCookie,
          marketingCookie,
          popUpTitle,
          popUpDescription,
        } = await SStrapiService.getCookieConsent();

        this.manageBtnText = manageButton || this.$t('cookieManagement.manageBtnText');
        this.acceptBtnText = acceptButton || this.$t('cookieManagement.acceptButton');
        this.bannerDescription = bannerDescription || this.$t('cookieManagement.bannerDescription');
        this.essentialCookiesTexts = essentialCookie
        || {
          title: this.$t('cookieManagement.essentialCookie.title'),
          description: this.$t('cookieManagement.essentialCookie.description'),
        };
        this.marketingCookiesTexts = marketingCookie
        || {
          title: this.$t('cookieManagement.marketingCookie.title'),
          description: this.$t('cookieManagement.marketingCookie.description'),
        };
        this.popUpTitle = popUpTitle || this.$t('cookieManagement.popUpTitle');
        this.popUpDescription = popUpDescription || this.$t('cookieManagement.popUpDescription');
        this.isCookieEnabled = SCookie.cookieEnabledChecker(essentialCookieConsent, cookieConsentValidValue);
      } catch (e) {
        // to-do rethink about catch
      }
    },
    acceptCookieConsent(acceptAll = false) {
      if (!SCookie.cookieEnabledChecker(essentialCookieConsent, cookieConsentValidValue)) {
        SCookie.deleteCookie(essentialCookieConsent);
        SCookie.setCookie(essentialCookieConsent, cookieConsentValidValue, cookieMaxDaysExpire);
      }

      if ((this.isMarketingCookiesAccepted || acceptAll)
        && !SCookie.cookieEnabledChecker(marketingCookieConsent, cookieConsentValidValue)) {
        SCookie.deleteCookie(marketingCookieConsent);
        SCookie.setCookie(marketingCookieConsent, cookieConsentValidValue, cookieMaxDaysExpire);
      } else {
        SCookie.deleteCookie(marketingCookieConsent);
        SCookie.setCookie(marketingCookieConsent, cookieConsentNotValidValue, cookieMaxDaysExpire);
        SCookie.removeDomainCookiesByPrefix(gtmCookiesPrefixes);
      }

      this.isCookieEnabled = SCookie.cookieEnabledChecker(essentialCookieConsent, cookieConsentValidValue);
      this.$router.go();
    },
    toggleAllUnnecessaryCookies() {
      this.isMarketingCookiesAccepted = false;
    },
    close() {
      this.isCookieDialogShown = false;

      if (this.getIsShownCookieDialog) {
        this.$store.commit('common/changeCookieDialogOpenState', false);
      }
    },
  },
}
</script>
