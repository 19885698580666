<template>
  <VRow
    class="d-flex"
    :class="{'flex-column': $vuetify.breakpoint.smAndDown, 'pl-6': $vuetify.breakpoint.smAndUp }"
  >
    <VCol
      lg="3"
      md="3"
      sm="12"
      xs="12"
      class="d-flex align-items-center"
      :class="{'flex-column': $vuetify.breakpoint.mdAndUp}"
    >
      <div :class="{'w-100': $vuetify.breakpoint.mdAndUp}">
        <VIcon
          large
          class="checkout-icon"
          :class="{'pr-1': $vuetify.breakpoint.smAndDown }"
        >
          mdi-ticket
        </VIcon>
      </div>
      <div :class="{'w-100': $vuetify.breakpoint.mdAndUp}">
        <span class="body-bold">{{ $t('nightRider.title') }}</span>
      </div>
    </VCol>
    <VCol
      cols="12"
      md="8"
      lg="8"
      sm="11"
    >
      <div class="pb-3">
        <span
          class="body-2"
          v-html="$t('nightRider.sectionTitle')"
        />
      </div>
      <ValidationObserver
        ref="nightCards"
        name="nightCards"
      >
        <div>
          <ValidationProvider
            v-for="(card, index) in cards"
            v-slot="{ errors, valid }"
            :key="index"
            ref="card"
            name="nightCard"
            :rules="{ min: 7, max: 10, onlyLettersAndNumbers: /^[a-zA-Z0-9]+$/, equalsCards: { cards: cards, cardValue: card.value } }"
            :allowFalse="false"
            mode="aggressive"
          >
            <VTextField
              v-model="card.value"
              clear-icon="mdi-close-circle"
              clearable
              :class="(((card.isBackendValid === false) || !card.isFrontValid) && !!card.value) ? 'pb-6' : ''"
              :label="$t('nightRider.nightCardLabel', { number: index + 1 }) "
              :error="!!errors.length"
              :error-messages="(card.isBackendValid === false)
                ? $t('nightRider.nightCardIsNotValid', { number: card.value })
                : validationErrorResolve(errors[0])"
              @input="[
                card.value = card.value
                  ? card.value.toUpperCase()
                  : card.value,
                removeFromLocalStorage(card),
                card.isBackendValid = null,
                card.isFrontValid = valid,
                checkCards(),
                trackNightCardEntering(card.value)
              ]"
            />
          </ValidationProvider>
        </div>
      </ValidationObserver>
    </VCol>
  </VRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { SValidationErrorMixin } from '@slg/web-customer-shared';
import { bookingRequestNightCardsCheck } from '@/services/BookingRequestsService';
import cardStatusEnum from '@/utils/cardStatusEnum';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'NightCards',
  mixins: [SValidationErrorMixin, GTMixin],
  props: {
    amountCards: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cards: [],
    }
  },
  computed: {
    ...mapGetters({
      getAdults: 'booking/getAdults',
      getChildren: 'booking/getChildren',
      getBookingRequestId: 'booking/getBookingRequestId',
    }),
  },
  created() {
    for (let i = 0; i < this.getAdults; i += 1) {
      this.cards.push({
        id: `card-${i}`,
        value: null,
        isFrontValid: false,
        isBackendValid: null,
      })
    }
    if (!localStorage.getItem('cards')) {
      return;
    }
    const cardsFromLocalStorage = JSON.parse(localStorage.getItem('cards'));
    const neededCardsFromLocalStorage = this.getAdults >= cardsFromLocalStorage.length
      ? cardsFromLocalStorage
      : cardsFromLocalStorage.slice(0, this.getAdults);

    if (neededCardsFromLocalStorage) {
      neededCardsFromLocalStorage.forEach(({
        id, value, isFrontValid, isBackendValid,
      }, index) => {
        this.cards[index].id = id;
        this.cards[index].value = value;
        this.cards[index].isFrontValid = isFrontValid;
        this.cards[index].isBackendValid = isBackendValid;
      })
    }
    this.checkCards();
  },
  methods: {
    trackNightCardEntering(cardValue) {
      if (cardValue.length < 7) {
        return;
      }
      this.trackGtmEvent('Enter night card', 'Input', 'Type', 'Entering Night Card');
    },
    async validateForm() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          const isFormValid = this.$refs?.nightCards?.validate();
          resolve(isFormValid);
        })
      });
    },
    removeFromLocalStorage(card) {
      const cardsFromLocalStorage = JSON.parse(localStorage.getItem('cards'));
      if (!cardsFromLocalStorage) {
        return;
      }
      const cardsSavedInLocalStorage = cardsFromLocalStorage.filter(({ id }) => (id !== card.id));
      localStorage.setItem('cards', JSON.stringify(cardsSavedInLocalStorage));
    },
    async checkCards() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      const bookingRequestNightCardsModel = {
        bookingRequestId: this.getBookingRequestId,
        adultsCount: this.getAdults,
        infantsCount: this.getChildren,
        nightRiderCards: this.cards.reduce((results, { value }) => {
          if (value) {
            results.push(value);
          }
          return results;
        }, []),
      };

      const { data } = await bookingRequestNightCardsCheck(bookingRequestNightCardsModel);
      const cardsFromLocalStorage = JSON.parse(localStorage.getItem('cards'));
      const cardsSavedInLocalStorage = cardsFromLocalStorage ? [...cardsFromLocalStorage] : [];

      Object.entries(data.nightRiderCardsValidationStatus).forEach(([key, value]) => {
        const cardIndex = this.cards.findIndex(({ value }) => key === value);
        // todo add checker for Prev API_Request => R3
        if (cardIndex < 0) {
          return;
        }
        if (value === cardStatusEnum.INVALID || value === cardStatusEnum.EXPIRED) {
          this.cards.splice(cardIndex, 1, { ...this.cards[cardIndex], isBackendValid: false });
        } else {
          const existingCard = cardsSavedInLocalStorage.find(({ value }) => value === this.cards[cardIndex].value);
          this.cards.splice(cardIndex, 1, { ...this.cards[cardIndex], isBackendValid: true });
          if (!existingCard) {
            cardsSavedInLocalStorage.push({
              id: this.cards[cardIndex].id,
              value: this.cards[cardIndex].value,
              isFrontValid: true,
              isBackendValid: true,
            })
          }
        }
      });

      if (cardsSavedInLocalStorage.length) {
        localStorage.setItem('cards', JSON.stringify(cardsSavedInLocalStorage));
      }

      const newPrice = {
        originalOutwardFeaturePrice: data.originalOutwardFeaturePrice,
        originalOutwardPrice: data.originalOutwardPrice,
        originalReturnFeaturePrice: data.originalReturnFeaturePrice,
        originalReturnPrice: data.originalReturnPrice,
      }
      const validCardsForOrder = this.cards.reduce((results, { value, isBackendValid }) => {
        if (isBackendValid) {
          results.push(value);
        }
        return results;
      }, []);
      this.$emit('updateNightCards', validCardsForOrder);
      this.$store.commit('booking/setUpdatedPriceByNightCards', newPrice);
    },
  },
}
</script>
