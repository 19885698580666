<template>
  <div class="w-100 h-100 d-flex justify-content-center">
    <SConfirmation
      :appConfig="getAppConfig"
      :orderId="getOrderId"
      :firstName="getFirstName"
      :departurePlace="getDeparturePlace"
      :arrivalPlace="getArrivalPlace"
      @toMainPage="toMainPage"
      @handleServerError="handleServerError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SConfirmation,
  SGlobalErrorDialogMixin,
  STripTypeEnum,
} from '@slg/web-customer-shared';
import appConfig from '@/appConfig';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'ConfirmationPage',
  components: {
    SConfirmation,
  },
  mixins: [
    SGlobalErrorDialogMixin,
    GTMixin,
  ],
  beforeRouteEnter(to, _from, next) {
    const hasOrderIdProperty = Object.prototype.hasOwnProperty.call(to.params, 'orderId');
    if (hasOrderIdProperty) {
      next();
    } else {
      // redirect to home page
      next('');
    }
  },
  computed: {
    ...mapGetters({
      getFirstName: 'auth/getFirstName',
      getDeparturePlace: 'booking/getDeparturePlace',
      getArrivalPlace: 'booking/getArrivalPlace',
      getTripType: 'booking/getTripType',
      getBookingDetails: 'booking/getBookingDetails',
    }),
    getOrderId() {
      return this.$route.params.orderId;
    },
    getAppConfig() {
      return appConfig;
    },
    isRoundTrip() {
      return this.getTripType === STripTypeEnum.ROUND_TRIP;
    },
    getTotalPrice() {
      return Number(this.getBookingDetails?.originalOutwardPrice) + Number(this.getBookingDetails?.originalReturnPrice);
    },
  },
  mounted() {
    this.trackGtmEvent('Confirmation page', 'Page', 'Loaded', 'Confirmation page is loaded');
    this.trackGtmEcommerceEvent('purchase', this.getTotalPrice, this.isRoundTrip);
  },
  beforeDestroy() {
    this.$store.commit('booking/clearBookingState');
    this.$store.commit('opsZone/clearOpsZone');
  },
  methods: {
    toMainPage() {
      this.$router.push({ name: 'Booking' });
    },
    async handleServerError(e) {
      await this.setGlobalError(e);
    },
  },
}
</script>

<style scoped>

</style>
