<template>
  <VContainer
    class="pt-10"
    fluid
  >
    <SContactUs @setGlobalLoading="setGlobalLoading" />
  </VContainer>
</template>

<script>
import { SContactUs } from '@slg/web-customer-shared';

export default {
  name: 'ContactUsPage',
  components: {
    SContactUs,
  },
  methods: {
    setGlobalLoading(loadingState) {
      this.$store.commit('common/setGlobalLoading', loadingState);
    },
  },
}
</script>

<style scoped>

</style>
