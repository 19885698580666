import Vue from 'vue';
import VueI18n from 'vue-i18n';
import appConfig from '@/appConfig';

Vue.use(VueI18n);

const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item))

const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line no-restricted-syntax
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    })
  }
  return mergeDeep(target, ...sources);
}

const getMessages = () => {
  const { languages } = appConfig;

  const messagesList = languages.map((locale) => {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const sharedMessages = require(`@slg/web-customer-shared/src/plugins/i18n/${locale}`);
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const appMessages = require(`./appMessages/${locale}`);
      return { locale, messages: mergeDeep(sharedMessages, appMessages) };
    } catch (e) {
      return { locale, messages: sharedMessages };
    }
  })

  return messagesList.reduce((accumulator, item) => {
    Object.defineProperty(accumulator, item.locale, { value: item.messages, enumerable: true });
    return accumulator;
  }, {});
}

export default new VueI18n({
  locale: 'en',
  messages: getMessages(),
});
