export default {
  methods: {
    trackGtmEvent(event, category, action, label, value = 5000, noninteraction = false) {
      this.$gtm.trackEvent({
        event, // Event type [default = 'interaction'] (Optional)
        category,
        action,
        label,
        value,
        noninteraction, // Optional
      });
    },
    trackGtmEcommerceEvent(event, price, isRoundTrip) {
      this.$gtm.trackEvent({
        event, // Event type [default = 'interaction'] (Optional)
        ecommerce: {
          item_name: 'Night ride',
          value: price,
          currency: 'EUR',
          item_category: isRoundTrip ? 'Round trip' : 'One way trip',
          quantity: 1,
          price,
        },
      });
    },
  },
}
