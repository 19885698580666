import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { VueGtm } from '@slg/web-customer-shared';
import router from '@/router/index';

const resolveSentryEnv = () => window.config?.VUE_APP_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT;
const resolveGtmContainerId = () => window.config?.VUE_APP_GTM_CONTAINER_ID || process.env.VUE_APP_GTM_CONTAINER_ID || 'GTM-5TVFF6Q';

export const connectToGtm = () => {
  Vue.use(VueGtm, {
    id: resolveGtmContainerId(),
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: '',
      gtm_preview: '',
      gtm_cookies_win: 'x',
    },
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ['homepage'],
    trackOnNextTick: false,
  });
};

export const connectToSentry = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Sentry.init({
    Vue,
    dsn: 'https://580e3138450e4fd2880cc3c660bab4e8@o915177.ingest.sentry.io/6467488',
    debug: false,
    environment: resolveSentryEnv(),
  });
};
