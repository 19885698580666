import { SStrapiService } from '@slg/web-customer-shared';
import { strapiInstance } from '@slg/web-customer-shared/src/services/StrapiService';

const getNightCardPage = () => strapiInstance.get('night-card');

const getCommunes = () => strapiInstance.get('communes?pagination[page]=1&pagination[pageSize]=100');

const getCommuneBySlug = (slug = '') => strapiInstance.get(`commune-by-slug/${slug}`);

const getCookieConsent = () => strapiInstance.get('cookie-consent', SStrapiService.addParamsForPopulate([
  'essentialCookie',
  'marketingCookie',
]));

export {
  getNightCardPage,
  getCommunes,
  getCommuneBySlug,
  getCookieConsent,
};
