<template>
  <div class="d-flex flex-column">
    <div class="booking-container position-relative">
      <div
        v-if="aboutPageData"
        class="h-100 position-relative d-flex justify-content-center flex-wrap mx-6 flex-column align-center"
      >
        <TwoColumnInfo
          :data="aboutPageData.section1"
          class="wrap mb-6 w-100"
        />
        <AdditionalInfo
          :data="aboutPageData.section2"
          :threeColumns="true"
          class="wrap mb-6 w-100"
          :small="true"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { getMetaInfo } from '@/utils/seo';
import { SGlobalErrorDialogMixin, SStrapiService, SUpdateCmsDataMixin } from '@slg/web-customer-shared';
import TwoColumnInfo from '@/components/strapi/TwoColumnInfo.vue';
import AdditionalInfo from '@/components/strapi/AdditionalInfo.vue';

export default {
  name: 'AboutPage',
  components: {
    TwoColumnInfo,
    AdditionalInfo,
  },
  mixins: [
    SGlobalErrorDialogMixin,
    SUpdateCmsDataMixin,
  ],
  data() {
    return {
      aboutPageData: null,
      test: null,
    }
  },
  metaInfo() {
    return getMetaInfo(this.aboutPageData?.Seo);
  },
  methods: {
    async loadCmsData() {
      try {
        this.aboutPageData = await SStrapiService.getAbout();
      } catch (error) {
        // to-do rethink about catch
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.booking-container {
  padding-top: 24px;
  padding-bottom: 31px;
  margin-bottom: 61px;
  min-height: 667px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;

  .booking-container__top {
    .subtitle-1 {
      max-width: 320px;
    }
  }

  .booking-form-container {
    z-index: 1;
  }

  .breakpoint-xl {
    @media screen and (max-width: 960px) {
      max-width: 810px;
    }
  }
}

.wrap {
  max-width: 810px;
}

@media screen and (min-width: 1280px) {
  .booking-container__top {
    .title-info {
      margin-top: 130px;
    }
  }
}

@media screen and (min-width: 960px) {
  .booking-container__top {
    .title-info {
      margin-top: 58px;
    }
  }
}

@media screen and (max-width: 960px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-32;
      line-height: $lh-43;
      letter-spacing: $ls-0025;
    }
  }
}

@media screen and (max-width: 600px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-20;
    }

    .subtitle-1 {
      font-size: $fs-14;
    }

    .booking-form-container {
      max-width: 360px;
    }
  }
}
</style>
