<template>
  <div>
    <SCancellingBookingDialog
      :isCancelingBookingDialogOpen="isCancelingBookingDialogOpen"
      @closeDialog="isCancelingBookingDialogOpen = !isCancelingBookingDialogOpen"
    />
    <STheHeader
      v-if="!isCheckoutPage"
      :isHeaderShown="isHeaderShown"
      :currentLang="getCurrentLang"
      :supportedLanguages="languages"
      :toolbarItems="getStrappiCoreMenu(toolbarItems)"
      :showOnClick="getStrappiCoreSettings.subMenuOnClick"
      :authToken="getAuthToken"
      :firstLetters="getFirstLetters"
      :fullName="getFullName"
      :cabinetToolbarItems="cabinetToolbarItems"
      :isLoadingLogOut="isLoadingLogOut"
      :logo="getStrappiCoreSettings.logo"
      :logoRedirectPath="getLogoRedirectPath"
      @setLang="setLang"
      @toAuthPage="toAuthPage"
      @logOut="logOut"
    />
    <SCheckoutHeader
      v-else
      :isHeaderShown="isHeaderShown"
      :appConfig="getAppConfig"
      :adultsCount="getAdults"
      :infantsCount="getChildren"
      :animalsTransportation="getAnimalsTransportation"
      :vehicleCategories="getVehicleCategories"
      :vehicleCategoryId="getVehicleCategoryId"
      :tripType="getTripType"
      :rideType="getRideType"
      :paymentType="getPaymentType"
      :selectedFeatures="getSelectedFeatures"
      :temporaryReservationDeadline="getTemporaryReservationDeadline"
      :remainingTime="getTemporaryReservationRemainingTime"
      :isAuth="!!getAuthToken"
      :ignoreRemainingTime="getIgnoreRemainingTime"
      @updateRemainingTime="updateRemainingTime"
      @cancelBooking="isCancelingBookingDialogOpen = !isCancelingBookingDialogOpen"
      @trackGtmEventReservationExpired="onTrackEventReservationExpired"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  STheHeader, SGlobalErrorDialogMixin, SCancellingBookingDialog, SStrapiService, SUpdateCmsDataMixin, SCheckoutHeader,
} from '@slg/web-customer-shared';
import appConfig from '@/appConfig';
import GTMixin from '@/mixins/GTMMixin';

export default {
  name: 'TheHeader',
  components: {
    STheHeader,
    SCheckoutHeader,
    SCancellingBookingDialog,
  },
  mixins: [
    SUpdateCmsDataMixin,
    SGlobalErrorDialogMixin,
    GTMixin,
  ],
  props: {
    isHeaderShown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoadingLogOut: false,
      isCancelingBookingDialogOpen: false,
      languages: null,
      toolbarItems: [
        {
          strapiText: '',
          strapiKey: 'homeTitle',
          text: 'header.home',
          value: 'Booking',
        },
        {
          text: 'header.about',
          value: 'About',
        },
        {
          text: 'header.nightCard',
          value: 'NightCard',
        },
        {
          strapiText: '',
          strapiKey: 'FAQTitle',
          text: 'header.faq',
          value: 'FAQ',
        },
        {
          strapiText: '',
          strapiKey: 'contactUsTitle',
          text: 'header.contactUs',
          value: 'ContactUs',
        },
      ],
      cabinetToolbarItems: [
        {
          text: 'cabinet.myTrips',
          icon: 'mdi-ticket-confirmation',
          value: 'Trips',
        },
        {
          text: 'cabinet.settings',
          icon: 'mdi-cog',
          value: 'Profile',
        },
        {
          text: 'cabinet.logOut',
          icon: 'mdi-logout-variant',
          value: 'LogOut',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAuthToken: 'auth/getAuthToken',
      getFirstLetters: 'auth/getFirstLetters',
      getFullName: 'auth/getFullName',
      getStrappiCoreSettings: 'common/getStrappiCoreSettings',
      getStrappiCoreMenu: 'common/getStrappiCoreMenu',
      getAdults: 'booking/getAdults',
      getChildren: 'booking/getChildren',
      getTripType: 'booking/getTripType',
      getRideType: 'booking/getRideType',
      getTemporaryReservationRemainingTime: 'booking/getTemporaryReservationRemainingTime',
      getIgnoreRemainingTime: 'booking/getIgnoreRemainingTime',
      getAnimalsTransportation: 'booking/getAnimalsTransportation',
      getVehicleCategoryId: 'booking/getVehicleCategoryId',
      getVehicleCategories: 'opsZone/getVehicleCategories',
      getSelectedFeatures: 'booking/getSelectedFeatures',
      getTemporaryReservationDeadline: 'booking/getTemporaryReservationDeadline',
      getPaymentType: 'booking/getPaymentType',
    }),
    getAppConfig() {
      return appConfig;
    },
    getLogoRedirectPath() {
      const { defaultLanguage } = appConfig;
      const currentLang = this.$route.params.lang || defaultLanguage;
      return { name: 'Booking', params: { lang: currentLang } };
    },
    getCurrentLang() {
      return this.$route.params.lang;
    },
    // due to problems with specific header and cookie banner on Chekout Page
    isCheckoutPage() {
      return this.$route.name === 'Checkout';
    },
  },
  created() {
    this.languages = appConfig.languages;
  },
  methods: {
    onTrackEventReservationExpired() {
      this.trackGtmEvent('Booking-Expired-Dialog-Ok-Button-Click', 'Button', 'Click', 'Redirect to home page');
    },
    async loadCmsData() {
      try {
        const res = await SStrapiService.getCoreSettings();
        this.$store.commit('common/setStrappiCoreSettings', res);
      } catch (error) {
        // to-do rethink about catch
      }
    },
    setLang(lang) {
      if (lang !== this.getCurrentLang) {
        this.$router.push({ params: { lang } });
      }
    },
    toAuthPage() {
      this.$router.push({ name: 'Auth' });
    },
    async logOut() {
      this.isLoadingLogOut = true;
      try {
        this.$store.commit('booking/clearTemporaryReservation');
      } catch (e) {
        await this.setGlobalError(e);
      }
      this.isLoadingLogOut = false;
      this.$store.commit('auth/clearAuthTokenAndProfileInfo');
      if (this.$route.name !== 'Booking') {
        await this.$router.push({ name: 'Booking' });
      }
    },
    updateRemainingTime(remainingTime) {
      this.$store.commit('booking/setRemainingTime', remainingTime);
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/style/headerLinks.scss";
.header {
  transform: translateY(0);
  transition: transform 300ms linear !important;
}
.header.is-hidden {
    transform: translateY(-100%) !important;
}

.feature-logo {
  width: 24px;
  height: 24px;
  opacity: 0;

  &--show {
    opacity: 1;
  }
}

.ps {
  width: auto;
  bottom: 0;
}

</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
