<template>
  <VRow>
    <template v-for="(item, key) in getCommunesGroupItems">
      <VCol
        :key="`Commune${key}`"
        class="py-3"
        cols="12"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <CommuneGroupItem :communeGroup="item" />
      </VCol>
    </template>
  </VRow>
</template>

<script>
import CommuneGroupItem from './CommuneGroupItem.vue';

export default {
  name: 'NightCardList',
  components: {
    CommuneGroupItem,
  },
  props: {
    communesList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getCommunesGroupItems() {
      const communeGroups = this.communesList.reduce((accumulator, currentValue) => {
        const { Title, OutOfService, Slug } = currentValue;
        const dataToSet = {
          title: Title,
          isOutOfService: !!OutOfService,
          slug: Slug,
        }
        const firstLetter = Title[0].toLowerCase();
        const letterGroup = accumulator.find((item) => item.letter === firstLetter);
        if (letterGroup) {
          letterGroup.items.push(dataToSet);
        } else {
          const groupItem = {
            letter: firstLetter,
            items: [dataToSet],
          }
          accumulator.push(groupItem);
        }
        return accumulator
      }, []);
      return communeGroups.sort((a, b) => {
        if (a.letter < b.letter) { return -1; }
        if (a.letter > b.letter) { return 1; }
        return 0;
      })
    },
  },
}
</script>

<style scoped>

</style>
