export const essentialCookieConsent = 'essential_cookie_consent';

export const marketingCookieConsent = 'marketing_cookie_consent';

export const oldCookieConsent = 'user_cookie_consent';

export const cookieConsentValidValue = 'allowed';

export const cookieConsentNotValidValue = 'not_allowed';

export const gtmCookiesPrefixes = ['_g'];

export const cookieMaxDaysExpire = 356;
