<template>
  <SSettingsProfile
    :firstLetters="getFirstLetters"
    :userDetailsForBookingDetails="getUserDetailsForBookingDetails"
    @setGlobalLoading="setGlobalLoading"
    @populateUserProfileInfo="populateUserProfileInfo"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { SSettingsProfile } from '@slg/web-customer-shared';

export default {
  name: 'SettingsProfile',
  components: {
    SSettingsProfile,
  },
  computed: {
    ...mapGetters({
      getUserDetailsForBookingDetails: 'auth/getUserDetailsForBookingDetails',
      getFirstLetters: 'auth/getFirstLetters',
    }),
  },
  methods: {
    setGlobalLoading(loadingState) {
      this.$store.commit('common/setGlobalLoading', loadingState);
    },
    async populateUserProfileInfo() {
      try {
        await this.$store.dispatch('auth/populateUserProfileInfo');
      } catch (e) {
        await this.setGlobalError(e);
      }
    },
  },
}
</script>
