<template>
  <VDialog
    ref="globalErrorDialog"
    v-model="getIsSessionExpiredDialogOpen"
    width="425"
    persistent
    outlined
  >
    <VCard>
      <VCardTitle class="pb-0">
        <span class="heading-6">
          {{ $t('authForm.sessionExpired') }}
        </span>
      </VCardTitle>
      <VCardText class="pb-0 pt-7">
        <div class="d-flex justify-content-center">
          <img
            src="@slg/web-customer-shared/src/assets/img/hands-key.svg"
            alt="...key"
          >
        </div>
        <div class="pt-6">
          <span class="body-1 textPrimary--text">
            {{ $t('authForm.sessionExpiredMessage') }}
          </span>
        </div>
      </VCardText>
      <VCardActions class="pb-4 pt-6">
        <VBtn
          class="w-100 shadow-none border-radius-8"
          color="brandingPrimary"
          dark
          @click="closeDialog"
        >
          <span class="button">
            {{ $t('general.ok') }}
          </span>
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'AccessDeniedDialog',
  computed: {
    getIsSessionExpiredDialogOpen() {
      return this.$store.getters['common/getIsSessionExpiredDialogOpen'];
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('auth/clearAuthTokenAndProfileInfo');
      this.$store.commit('common/changeSessionExpiredDialogOpenState', false);
      this.$router.push({ name: 'Booking', lang: this.$route.params.lang });
    },
  },
}
</script>

<style scoped>

</style>
